@import "../../styles/variables";

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: radial-gradient(50% 50% at 50% 50%, #E73843 0%, #782E32 27.08%, #262626 100%);
    button {
        width: 260px !important;
        height: 48px !important;
        font-size: 16px !important;
    }
}

.image {
    width: 444px;
    height: 400px;

    @media screen and (max-width: $Medium-md-l) {
        width: 310px;
        height: 280px;
    }

    @media screen and (max-width: $X-Small-m) {
        width: 266px;
        height: 240px;
    }
}

.title {
    padding: 24px 0 16px;
    font-family: $primary-font-family;
    font-weight: $font-bold;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #FFF;

    @media screen and (max-width: $Medium-md-l) {
        font-size: 32px;
    }

    @media screen and (max-width: $X-Small-m) {
        padding: 40px 0 8px;
        font-size: 24px;
    }
}

.description {
    padding-bottom: 40px;
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFF;

    @media screen and (max-width: $Medium-md-l) {
        font-size: 18px;
        line-height: 22px;
    }

    @media screen and (max-width: $X-Small-m) {
        font-size: 16px;
        line-height: 20px;
    }
}
