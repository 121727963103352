@import "../../styles/variables.scss";

.wrapperInputLine {
    button {
        padding: 8px;
        border: none;
    }
}

.inputLine {
        border: none !important;
        border-bottom: 1px solid $primaryBorderLine !important;
        border-radius: 0;
        font-size: 13px;
        line-height: 16px;
        padding: 12px 0;
        height: 40px;
        font-weight: 500;
        caret-color: #404040;
        color: #000;

        &:focus {
          border-bottom: 1px solid #2d2a26 !important;
        }

        &::placeholder {
            color: $primary-dark-grey-color !important;
        }

        &.error {
          border-bottom: 1px solid $text-error !important;
        }

        &.newSignUpForm {
          background: #F5F6FA !important;
          border: 1px solid #9CA4BF !important;
          border-radius: 4px;
          padding: 16px 0 16px 16px;
          height: 48px;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            background: #F5F6FA !important;
            -webkit-box-shadow: 0 0 0 1000px #F5F6FA inset !important;
          }

          &::placeholder {
            font-size: 13px !important;
            line-height: 24px;
            color: #999dad !important;
          }
        }
}

.forgotPasswordInputLine {
  border: 1px solid $primaryBorderLine !important;
  border-radius: 4px;
  padding: 14px 16px;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  font-weight: 400;
  caret-color: #404040;
  color: #000000;
  background-color: $primary-text-light-color !important;

  &:hover {
    border-bottom: 1px solid #000;
  }

  &::placeholder {
    color: $primary-dark-grey-color !important;
  }

  &.error {
    border-bottom: 1px solid $text-error;
  }

  &.newSignUpForm {
    border-bottom: none;
    &:hover {
      border-bottom: none;
    }
  }
}

.topLabelLine_focus {
  top: -12px;
  left: 0;
  padding: 0;
  background: none;
  font-weight: 500;
  font-size: 11px;
  line-height: 13.4px;
  color: $primaryBorderLine;

  &.newSignUpForm {
    position: relative;
    top: 0;
    left: 0;
  }
}

.forgotPasswordTopLabelLine_focus {
  position: absolute;
  top: -8px;
  left: 10px;
  font-family: Montserrat,sans-serif;
  font-weight: $font-semibold;
  font-size: 10px;
  line-height: 17px;
  color: $input-placeholder-color;
  padding: 0 5px;
  z-index: 1;
  background: #fff;
  cursor: text;
}
