@mixin scrollVertical {
    &::-webkit-scrollbar {
        width: 6px;
        height: 20px;
    }

    &::-webkit-scrollbar-track {
        background-color: #22222E;
        border-radius: 1vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #31313E;
        border-radius: 100vw;
    }

    scrollbar-width: thin;
    scrollbar-color: #31313E;
}


@mixin background {
    position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1220;
        background: #04040Bcc;
        display: flex;
        height: 100%;
        padding: 30px;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;

        @media screen and (max-width: $X-Small) {
            padding: 0;
            overflow: auto;
        }

        @media screen and (max-width: $X-Small-m) {
            padding: 0;
        }
}
