@import "../../../styles/variables";

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding: 24px 40px 100px;
    color: $primary-text-light-color;
    background-color: $footer-bg;

    @media screen and (max-width: $Extra-large-s) {
        padding: 24px 16px 100px;
    }

    @media screen and (max-width: $Large-lg-l) {
        padding: 24px 16px 40px;
    }

    @media screen and (max-width: $Large-lg) {
        gap: 20px;
    }

    @media screen and (max-width: $Medium) {
        padding: 24px 10px 85px;
    }

    @media screen and (max-width: $X-Small) {
        gap: 12px;
    }
}

.footer__center {
    width: 100%;
    max-width: $site-full-width;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: $Medium-md-l) {
        gap: 16px;
    }

    @media screen and (max-width: $X-Small) {
        flex-direction: column;
        gap: 0;
    }

    @media screen and (orientation: landscape) {
        flex-direction: row;
    }
}

.footer__nav__col__wrapper {
    display: flex;
    flex-direction: row;
    flex-basis: 65%;
    gap: 24px;

    @media screen and (max-width: $Extra-large-xl) {
        flex-basis: 100%;
        border-bottom: 1px solid $dark-blue-color300;
    }

    @media screen and (max-width: $Large-lg) {
        .footer__title {
            margin-bottom: 21px;
        }
    }

    @media screen and (max-width: $Medium-md-l) {
        flex-direction: column;
        flex-wrap: nowrap;
        text-align: center;
        flex-basis: 100%;
        gap: 16px;
        padding-bottom: 16px;
        margin-bottom: 16px;

        .footer__title {
            margin-bottom: 14px;
        }
    }

    @media screen and (max-width: $Medium) {
        flex-direction: column;
        gap: 0;
        margin-bottom: 0;
        padding-bottom: 0;

        border-bottom: none;
    }
}

.footer__col__wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    grid-gap: 24px;
    gap: 24px;

    @media screen and (max-width: $Medium-md) {
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 0;
        grid-gap: 16px;
        gap: 16px;
        padding-right: 24px;
        border-right: 1px solid $dark-blue-color300;

        .footer__title {
            font-size: 13px !important;
        }
    }

    @media screen and (max-width: $Medium) {
        align-items: inherit;
        flex-basis: 100%;
        grid-gap: 0;
        gap: 0;
        padding-right: 0;
        border-right: none;

        .footer__col {
            margin-bottom: 12px;
            padding-top: 12px;
            border-top: 1px solid $dark-blue-color300;
        }

        .footer__title {
            font-size: 14px !important;
        }
    }
}

.footer__col {
    flex: 1 0 13%;

    @media screen and (max-width: $Medium-md) {
        flex: 1 1 13%;
    }

    @media screen and (max-width: $Medium) {
        margin-bottom: 12px;
    }
}

.footer__col__payments {
    @extend .footer__col;
    flex-basis: 100%;

    .footer__title {
        font-size: 13px !important;
        font-weight: 600;
    }

    @media screen and (max-width: $Extra-large-xl) {
        flex: 1 0 0;
    }

    @media screen and (max-width: $Medium-md) {
        display: flex;
        justify-content: center;
        flex: 0 0 33%;
        padding-right: 24px;
        padding-left: 24px;
        border-right: 1px solid #2F3B52;
    }

    @media screen and (max-width: $Medium) {
        flex: 0 0 100%;
        margin-bottom: 0;
        padding-top: 12px;
        padding-right: 0;
        padding-left: 0;
        border-top: 1px solid $dark-blue-color300;
        border-right: none;
    }
}

.footer__links {
    margin-bottom: 24px;

    @media screen and (max-width: $Large-lg) {
        display: flex;
    }
}

.footer__title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: $font-semibold;
    line-height: 29px;
    color: $primary-text-light-color;
    text-transform: capitalize;

    @media screen and (max-width: $Medium-md-l) {
        font-size: 14px !important;
        line-height: 1px;
    }

    @media screen and (max-width: $Medium) {
        text-align: center;
    }
}


.footer__title__arrow {
    position: relative;

    &:after {
        content: '';
        width: 20px;
        height: 10px;
        transform: rotate(180deg);
        background: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6667 6.21484L8.00008 10.8815L3.33342 6.21484' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
    }
}

.footer__title__active {
    &:after {
        transform: rotate(0);
    }
}

.footer__navbar__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 21px;

    @media screen and (max-width: $Large-lg) {
        li {
            padding-bottom: 21px;
        }
    }

    @media screen and (max-width: $Medium-md-l) {
        padding-bottom: 0;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 12px;

        li {
            padding-bottom: 10px;
        }
    }

    @media screen and (max-width: $Medium-md) {
        column-gap: 0;

        li {
            padding: 0 8px 10px;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: calc(100% - 16px);
        margin: 0 auto;
    }

    @media screen and (max-width: $X-Small) {
        padding: 0 16px;
    }
}

.footer__navbar__list__contacts {
    @media screen and (max-width: $Medium-md) {
        justify-content: space-evenly;

        li {
            width: 35%;
            font-size: 14px;
            font-weight: 600;
        }

        li:nth-child(2) {
            width: 25%;
        }
    }

    @media screen and (max-width: $X-Small) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 24px;

        li,
        li:nth-child(2) {
            width: 100%;
        }
    }
}

.footer__navbar__link__contacts {
    text-decoration-color: transparent;

    & > span {
        display: inline-block;
        text-decoration-color: transparent;
    }

    @media (hover: none) {
        text-decoration: none;
        text-decoration-color: transparent;

        & > span {
            display: inline-block;
            text-decoration: none;
            text-decoration-color: transparent;
        }
    }
}

.footer__subtitle__contacts {
    display: inline-block;
    padding-left: 4px;
    font-size: 9px;
    font-weight: $font-light;
    line-height: 24px;

    & > span {
        display: inline-block;
    }

    &:hover {
        text-decoration: none;
        text-decoration-color: transparent;

        & > span {
            display: inline-block;
            text-decoration: none;
            text-decoration-color: transparent;
        }
    }
}

.footer__navbar__icon {
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.footer__navbar__link {
    display: flex;
    align-items: center;
    font-family: $primary-font-family;
    font-weight: $font-regular;
    font-size: 14px;
    line-height: 36px;
    text-transform: capitalize;
    text-decoration: underline;
    text-decoration-color: transparent;
    white-space: nowrap;
    color: $light-gray-color600;
    cursor: pointer;
    transition: all .3s;

    &:hover {
        text-decoration-color: $light-gray-color600;
    }

    @media screen and (max-width: $Large-lg) {
        font-size: 12px;
        line-height: 12px;
    }

    @media screen and (max-width: $Medium-md-l) {
        justify-content: center;
    }
}

.footer__payments {
    display: grid;
    grid-template-columns: repeat(auto-fill, 50px);
    gap: 8px;
    width: 100%;

    &:hover{
        cursor: pointer;
    }

    @media screen and (max-width: $Medium-md-l) {
        grid-template-columns: repeat(4, 47px);
    }

    @media screen and (max-width: $Medium-md) {
        grid-template-columns: repeat(5, 47px);
    }

    @media screen and (max-width: $mobile-breakpoint) {
        grid-template-columns: repeat(4, 47px);
    }

    @media screen and (max-width: $Medium) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0;
    }
}

.footer__payments__card {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3px;
    border-radius: 4px;
    background-color: $light-bg-color;

    img {
        max-width: 75%;
        height: 25px;
    }

    @media screen and (max-width: $Medium-md) {
        width: 100%;
        max-width: 47px;
    }

    @media screen and (max-width: $Medium) {
        max-width: 48px;
        margin: 0 4px 8px;
        border-radius: 2px;
    }
}


.footer__text {
    width: 100%;
    font-family: $primary-font-family;
    font-weight: $font-light;
    font-size: 12px;
    line-height: 18px;
    color: $primary-text-light-color;

    p:nth-of-type(2) {
        margin-top: 16px;
    }

    .footer__title {
        font-size: 13px;
        line-height: 29px;
    }

    @media screen and (max-width: $Extra-large-xl) {
        padding-top: 16px;
        border-top: 1px solid $dark-blue-color300;
    }

    @media screen and (max-width: $Medium-md) {
        .footer__title {
            text-align: left;
        }
    }

    @media screen and (max-width: $Medium) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 12px;

        .footer__title {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    @media screen and (max-width: $X-Small) {
        .footer__title {
            font-size: 14px;
        }
    }
}

.footer__content {
    width: 100%;

    & > p {
        font-size: 11px;
        line-height: 18px;
        font-weight: $font-light;
    }

    @media screen and (max-width: $X-Small) {
        text-align: center;
    }
}

.footer__apps {
    display: flex;
    width: 152px;
    flex-direction: column;
    gap: 8px;

    @media screen and (max-width: $Medium-md) {
        width: 220px;
        flex-direction: row;
        margin: 0 auto;
    }
}

.footer__apps__link {
    display: inline-flex;
    align-items: center;
    height: 45px;

    img {
        display: inline-block;
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: $Medium-md) {
        width: 100%;
        height: 32px;
    }
}

.footer__logos {
    display: flex;
    align-items: center;

    &__regulator {
        width: 98px;
        height: 24px;
        object-fit: cover;
    }

    @media screen and (max-width: $Medium-md) {
        justify-content: center;
    }
}

.footer__logos__age {
    display: flex;
    min-width: 32px;
    min-height: 32px;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 12px;
    line-height: 15px;
    border: 1.5px solid #E10000;
    border-radius: 50%;
    text-transform: capitalize;
    color: #E10000;
    text-shadow: 0px 18.1818px 36.3636px rgba(0, 0, 0, 0.25);
}

.socialMedia__title {
    margin-right: 24px;
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: $primary-text-color;
}

.socialMedia__icons {
    display: grid;
    grid-template-columns: repeat(3, 42px);
    gap: 8px;

    @media screen and (max-width: $Medium-md) {
        grid-template-columns: repeat(3, 26px);
    }

    @media screen and (max-width: $Medium) {
        place-content: center;
    }
}

.socialMedia__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    background: #2f3b52;
    border-radius: 5px;
    cursor: pointer;

    img {
        width: 23px;
        height: 23px;
    }

    @media screen and (max-width: $Medium-md) {
        height: 26px;

        img {
            width: 13px;
            height: 13px;
        }
    }
}

.footer__isOpen {
    display: none;
}

.lowercased {
    text-transform: lowercase;
}

.award__logo__wrapper {
    position: absolute;
    right: -24px;
    top: 44%;

    @media screen and (max-width: $Extra-large-xl) {
        position: static;
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid #2f3b52;
    }

    @media screen and (max-width: $Medium-md-l) {
        justify-content: flex-end;
    }

    @media screen and (max-width: $Medium-md) {
        display: none;
    }
}

.award__logo__wrapper__tablet {
    @extend .award__logo__wrapper;
    display: none;
    border-top: none;

    @media screen and (max-width: $Medium-md) {
        display: flex;
        flex: 1 0 20%;
        justify-content: center;
        margin-top: 0;
        padding-top: 0;
    }

    @media screen and (max-width: $Medium) {
        display: none;
    }
}

.award__logo__wrapper__mobile {
    display: none;

    @media screen and (max-width: $Medium) {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-left: 0;
        text-align: center;
        border-left: none;
    }
}

.award__logo {
    width: 200px;
    height: 130px;

    @media screen and (max-width: $Extra-large-xl) {
        width: 157px;
        height: 103px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 145px;
        height: 93px;
    }

    @media screen and (max-width: $X-Small) {
        width: 157px;
        height: 103px;
        margin: 0 auto;
    }
}
