@import "../../styles/variables";
@import "../../styles/mixin";

.wrapper {
    position: relative;
    user-select: none;
    border: 1px solid #9CA4BF;
    border-radius: 4px;
    font-size: 13px;
    line-height: 16px;
    padding: 12px 0 12px 16px;
    height: 48px;
    font-weight: 500;
    caret-color: #404040;
    color: #000;
    text-align: start;
    cursor: pointer;
    background: #F5F6FA url('../../UI/Select/chevron-down.svg') no-repeat !important;
    background-position-x: calc(100% - 16px) !important;
    background-position-y: 12px !important;
}

.placeholder {
    height: 48px;
    width: 100%;
    display: flex;
    padding: 0 64px 0 16px;
    align-items: center;
    background: $popup-container-bg;
    border: 1px solid $input-border-color_for_dark_theme;
    box-sizing: border-box;
    border-radius: $input-border-radius;
    font-family: $primary-font-family;
    font-weight: $font-regular;
    font-size: 14px;
    line-height: 17px;
    color: $primary-white-color;
    background: url('./arrow_active.svg') no-repeat;
    background-position-x: calc(100% - 23px);
    background-position-y: 18px;
    cursor: pointer;

    @media screen and (max-width: $Medium-md-l) {
        height: 56px;
        font-size: 16px;
        line-height: 20px;
        background-position-y: 22px;
    }
}

.wrapperResetIcon {
    right: 36px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 36px;
    height: 36px;
    background-color: #1a1818;

    &:hover {
        background-color: #2a2a2a;
    }

    svg {
        path {
            stroke: #FBFBFB;
        }
    }
}

.placeholder_upload_document {
    @media screen and (max-width: $Medium-md-l) {
        height: 49px;
        font-size: 14px;
        line-height: 17px;
    }

    &.placeholderOpenStatus {
        background: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.82199 1.18645C5.42431 0.612441 4.57569 0.612441 4.17801 1.18645L0.891226 5.93051C0.431747 6.59371 0.906402 7.5 1.71322 7.5L8.28678 7.5C9.0936 7.5 9.56825 6.59371 9.10877 5.93051L5.82199 1.18645Z' fill='white'/%3E%3C/svg%3E%0A") no-repeat 0 0 !important;
        background-position-x: calc(100% - 23px) !important;
        background-position-y: 18px !important;
    }
}

.placeholder.placeholderOpenStatus {
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: none;
}

.placeholder_inactive {
    @extend .placeholder;

    border: 1px solid $input-placeholder-color-filled_disabled;
    color: $input-placeholder-color-filled_disabled;
    background: url('./arrow.svg') no-repeat;
    background-position-x: calc(100% - 5px);
    background-position-y: center;
}

.placeholder_unfilled {
    @extend .placeholder;

    color: $input-placeholder-color;
    border: 1px solid $input-placeholder-color-filled_disabled;
    background: url('./arrow.svg') no-repeat;
    background-position-x: calc(100% - 23px);
    background-position-y: 18px;

    @media screen and (max-width: $Medium-md-l) {
        background-position-y: 22px;
    }
}

.select {
    @include scrollVertical;

    top: 48px;
    right: 0;
    position: absolute;
    width: 100%;
    height: auto;
    max-height: 205px;
    overflow: hidden;
    font-family: $primary-font-family;
    font-weight: $font-regular;
    font-size: 13px;
    line-height: 16px;
    color: $primary-text-light-color;
    z-index: 999;
    cursor: pointer;
    border: 1px solid $input-border-color_for_dark_theme;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #F5F6FA;
    box-shadow: 0 4px 10px #BFBFC5;
    border-radius: 4px;

    &.upload__document__list {
        max-height: none;
        background-color: $container-bg-dark;

        ul {
            max-height: none;
            overflow-y: scroll;
            height: 162px;
        }
    }
}

.select_white {
    @extend .select;

    color: $primary-text-color;
    background-color: #fff;

    ul {
        &::-webkit-scrollbar-track {
            background-color: #F8F7FA;
            border-radius: 1vw;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #DEDEE0;
            border-radius: 100vw;
        }
    }
}

.option {
    height: 32px;
    padding: 14px 16px;
    line-height: 7px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;

    &:last-child {
        height: auto;
    }
}

.option_black {
    @extend .option;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $primary-text-color;
}

.option:hover {
         background: #353535;
     }
.option.selected {
         background: #2D2A26;
         font-weight: $font-bold;
    &.isRegistrationSelect {
        background: #9CA4BF;
    }
}

.option_black.selected_red {
    color: #fff;
    background: $primary-red-color;
    font-weight: $font-bold;
}

.topLabel {
    font-family: $primary-font-family;
    text-transform: capitalize;
    z-index: 1;
    color: #555353;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    padding: 0;
    width: fit-content;
}

.topLabel_disable {
        color: $input-placeholder-color-filled_disabled;
}

.simpleLabel {
        width: 100%;
        text-align: start;
        font-family: $primary-font-family;
        font-size: 16px;
        line-height: 23px;
        text-transform: capitalize;
        font-weight: $font-semibold;
        color: #8F96AA;
        margin-bottom: 5px;
        display: inline-block;

        @media screen and (max-width: $X-Small-m) {
            margin-bottom: 8px;
        }
}

.searchIcon {
    color: #3D3D50;
    top: 2px;
    position: absolute;
    left: 32px;
    z-index: 10;
    cursor: pointer;

    &.searchForGames {
        @media screen and (max-width: $Medium) {
            top: 12px;
        }
    }
}

.input {
    width: 100%;
    height: 40px;
    padding-left: 47px;
    background: $container-bg-light;
    font-family: $primary-font-family;
    font-size: 14px;
    line-height: 17px;
    color: $primary-white-color;
    border-radius: $element-border-radius;
    outline: none;
    border: none;

    &::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $input-placeholder-color;
    }
}

.searchWrapper {
    position: sticky;
    top: 12px;
    padding: 0 16px 10px;
    margin: 12px 0 16px;

    &::after {
        position: absolute;
        bottom: 1px;
        left: 50%;
        content: '';
        transform: translateX(-50%);
        width: 90%;
        height: 1px;
        background-color: $primary-text-light-color;

    }

    input {
        height: 28px !important;
        background: $primary-text-light-color !important;
        border-radius: 40px !important;
        color: #BDBDBD !important;

        &::placeholder {
            font-size: 13px !important;
            line-height: 16px !important;
            color: #BDBDBD !important;
        }
    }

    svg {
        width: 20px;
        height: 25px;
    }
}

.ul {
    max-height: 130px;
    overflow: auto;
    width: 100%;
    scrollbar-color: #bdbdbd transparent;
    
    &::-webkit-scrollbar {
        width: 8px;
        height: 20px;
    }

    &::-webkit-scrollbar-track {
        background-color: #EFEFF3;
        border-radius: 1vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #D3D3E2;
        border-radius: 100vw;
    }

    li {
        color: #494949;
        text-align: center;
        &:hover {
            background: #9ca4bf;
        }
    }
}

.errorText {
    background: #fff;
    color: $text-error;
    padding-top: 4px;
    font-family: $primary-font-family;
    font-size: 11px;
    line-height: 13px;
    text-align: start;
}
