@import "../../../styles/variables.scss";

.titleCode {
    font-weight: $font-bold;

    @media screen and (max-width: $X-Small-m) {
        text-transform: uppercase;
    }
}

.method {
    font-weight: $font-bold;

    @media screen and (max-width: $X-Small-m) {
        font-weight: $font-semibold;
    }
}

.timer {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 16px 0;
    color: $primary-text-dark-color;
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
}

.resend {
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    color: #1A1818;
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-transform: capitalize;

    & + button {
        color: #1A1818;
        font-weight: $font-bold;
    }
}

.button {
    @extend .resend;

    text-decoration: underline;
    cursor: pointer;
}

.error {
    display: grid;
    grid-template-rows: 0fr;
    margin-top: 4px;
    overflow: hidden;
    transition: grid-template-rows .3s ease-in-out;

    @media screen and (max-width: $X-Small-m) {
        margin-top: 6px;
    }

    &.extended {
        grid-template-rows: 1fr;
    }

    &__text {
        min-height: 0;
        color: #D43B3B;
        font-family: $primary-font-family;
        font-weight: $font-regular;
        font-size: 13px;
        line-height: 24px;
        text-transform: capitalize;
    }
}

.needHelp {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4px;
    width: 100%;

    @media screen and (max-width: $X-Small-m) {
        margin-top: 6px;
    }

    .info {
        display: grid;
        grid-template-rows: 0fr;
        overflow: hidden;
        transition: grid-template-rows .3s ease-in-out;

        &.extended {
            grid-template-rows: 1fr;
        }

        &__text {
            min-height: 0;
            color: $primary-text-dark-color;
            font-family: $primary-font-family;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
        }

        &__link {
            color: $primary-text-dark-color;
            font-family: $primary-font-family;
            font-size: 13px;
            font-weight: $font-semibold;
            line-height: 19.5px;
            text-decoration: underline;
            cursor: pointer;
            white-space: nowrap;
            text-align: center;
        }
    }
}

.presentbox__icon {
    width: 58px;
    height: 52px;

    img {
        width: 100%;
    }

    svg {
        width: 58px;
        height: 52px;
    }

    @media screen and (max-width: $X-Small-m) {
        width: 44px;
        height: 44px;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.title,
.description,
.hint {
    color: $secondary-dark-color;
}

.title {
    padding-top: 15px;
    font-size: 16px;
    font-weight: $font-semibold;
    line-height: 28px;
    text-transform: uppercase;
}

.description {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin-top: 5px;
}

.digitCodeInputWrapper {
    input {
        border: 1px solid #9CA4BF;
        background: #F5F6FA;
    }
}
