// MAIN COLORS
$primary-bg-color: #111a29;
$secondary-bg-color: #000;
$primary-dark-color: #000;
$secondary-dark-color: #1A1818;
$primary-white-color: #FBFBFB;
$primary-yellow-color: #FFC949;
$primary-red-color: #E73843;
$primary-gold-color: #AE8420;
$primary-gray-color: #6A7187;
$primary-dark-grey-color: #6C6C6C;
$primary-text-color: #FFFFFF;
$primary-text-dark-color: #2D2A26;
$secondary-text-color: #0E0E0E;
$attention-text-color: #E3001B;
$primary-text-light-color: #fff;
$secondary-gray-color: #7A768F;
$light-bg-color: #fff;
$light-gray-color600: #B1C3E5;
$dark-blue-color300: #2F3B52;

$primary-hover-color: #DBA62C;

$popup-container-bg: #15151F;
$popup-container-light: #fff;
$popup-container-br: 8px;
$popup-container-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.10);
$popup-title: #1a1818;
$popup-btn-dark: #38445a;
$popup-close-icon: #999DAD;
$popup-close-icon-hover: #73788C;

$light-text-color: #ff0000;
$text-error: #B00020;

$primary-verified-color: #24994C;
$primary-declined-color: #C82C16;

$popup-shadow-color: 0px 4px 10px #04040B;

// separator line color
$tabs-separator-color: #161625;
$table-header-separator-color: rgba(194, 190, 207, .3);
$table-body-separator-color: rgba(255, 255, 255, .1);
$side-menu-separator: #4A4A4A;

// Colors for Game Categories section on the Homepage
$allgames-primary-color: #323246;

// Background color for Promotions cards
$promotion-card-bg: #0D0D17;

// Background color for containers and popup
$container-bg: #15151F;
$container-bg-light: #22222E;

// separator line color
$tabs-separator-color: #161625;
$table-header-separator-color: rgba(194, 190, 207, .3);
$table-body-separator-color: rgba(255, 255, 255, .1);

$input-placeholder-color-filled_disabled: #A7A7AF;
$input-placeholder-color-filled_disabled_dark: #898996;
$input-validation-bg-color: #F5F6FA;

// footer background colors
$footer-bg: #111a29;

// Main max width
$site-full-width: 1920px;
$site-max-width: 1290px;
$content-max-width: 1240px;

// Breakpoint
$mobile-breakpoint: 768px;

$X-Small-xs: 360px;
$X-Small-s: 414px;
$X-Small-m: 480px;
$X-Small-l: 567px;
$X-Small: 599px;
$Medium: 700px;
$Medium-md-s: 847px;
$Medium-md: 899px;
$Medium-md-l: 1024px;
$Large-lg-s: 1156px;
$Large-lg: 1199px;
$Large-lg-l: 1366px;
$Extra-large-m: 1600px;
$Extra-large-s: 1480px;
$Extra-large-xl: 1800px;

// HOTFIX: need to change to mixins and use only above breakpoints
$Medium-md-min: 600px;
$Large-lg-min: 900px;
$Extra-large-xl-min: 1200px;

// fonts
$primary-font-family: Montserrat, sans-serif;
$secondary-font-family: Jost, sans-serif;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-black: 800;

// footer background colors

$footer-primary-color: #202934;
$footer-secondary-color: #1e252d;
$footer-primary-bg-color: #05070A;

// header background colors
$header-primary-bg-color: #05070A;
$header-dark-bg-color: #121212;

// Containers:
$container-bg-color: #15151F;
$container-border-radius: 8px;
$container-shadow-color: rgba(0, 0, 0, 1);
$container-background-filter: 16px;
$container-bg-dark: #1a1818;

$container-secondary-bg-color: #424550;
$container-secondary-border-radius: 16px;

// Border radius
$element-border-radius: 4px; // using for input, buttons
$block-border-radius: 8px;

$primaryBorderLine: #898996;

// Input:
$input-border-color: #323246;
$input-border-radius: 4px;
$input-color: #ffffff;
$input-placeholder-color: #3D3D50;
$input-background-color: transparent;

// Buttons:
$btn-getapp-bg-color: #191925;

$button-border-radius: 40px;
$btn-default-color: #09111D;
$btn-default-border-color: #FBFA00;
$btn-default-hover-color: green;
$btn-default-hover-bg-color: green;

$btn-primary-bg-color: #AE8420;
$btn-primary-bg-color-green: #87f031;
$btn-primary-border-color: #AE8420;
$btn-primary-hover-bg-color: #DBA62C;
$btn-primary-bg-color-green-hover: #8fe846;
$btn-primary-hover-border-color: #DBA62C;
$input-border-color_for_dark_theme: #fff;
$btn-primary-green-outlined-hover: rgba(135, 240, 49, 0.20);

$btn-secondary-bg-color: transparent;
$btn-secondary-border-color: #AE8420;
$btn-secondary-hover-bg-color: #DBA62C;
$btn-secondary-hover-border-color: #DBA62C;

$svg-color-inactive: #323246;
$svg-color-inactive-hover: #7A768F;

$btn-secondary-color-inactive: #5E6270;
$btn-secondary-bg-color-inactive: #282D35;
$btn-secondary-hover-color-inactive: #5E6270;
$btn-secondary-hover-bg-color-inactive: transparent;
$btn-secondary-hover-border-color-inactive: #5E6270;
$btn-secondary-border-color-inactive: #5E6270;

$btn-alert-color: green;
$btn-alert-bg-color: green;
$btn-alert-border-color: green;
$btn-alert-hover-color: green;
$btn-alert-hover-bg-color: green;
$btn-alert-hover-border-color: green;
