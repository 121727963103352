@import "../../styles/variables.scss";

.toast-container {
    .closeIcon {
        margin-top: 8px;
        margin-right: 8px;
        > svg {
            fill: #fff
        }
    }

    .Toastify__toast-body {
        font-family: Jost;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        padding-left: 12px;
        color: $primary-white-color;

        *:last-child {
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .Toastify__toast-icon {
        margin-right: 16px;
        display: block;

        > svg {
            width: 24px;
            height: 24px;
            }
    }

    .Toastify__toast-theme--dark {
        background-color: $popup-container-bg;
        color: $primary-white-color;
    }

    .Toastify__toast-theme--dark.Toastify__toast--info {
        border-left: 4px solid #4972DC;
    }
    .Toastify__toast-theme--dark.Toastify__toast--success {

    }
    .Toastify__toast-theme--dark.Toastify__toast--warning {
        border-left: 4px solid #FAC23D;
    }
    .Toastify__toast-theme--dark.Toastify__toast--error {
        border-left: 4px solid #CE132D;
    }
}
