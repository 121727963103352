@import '../../styles/variables';

.wrapperInput {
    width: 100%;
    position: relative;

    .errorText {
        color: $text-error;
        margin-top: 4px;
        font-family: $primary-font-family;
        font-size: 12px;
        line-height: 18px;
        padding-left: 8px;
        text-align: start;
    }

    .searchIcon {
        color: #3D3D50;
        top: 6px;
        position: absolute;
        left: 16px;
        z-index: 10;
    }
}

.wrapperInput.medium {
    width: 288px;
}

.input {
    width: 100%;
    height: 100%;
    max-height: 48px;
    font-family: $primary-font-family;
    font-size: 14px;
    line-height: 17px;
    padding: 14px 16px;
    background: transparent;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    color: $input-color;
    outline: none;
    font-weight: 400;

    @media screen and (max-width: $Medium-md-l) {
        max-height: unset;
        height: 56px;
    }

    @media screen and (max-width: $Medium-md) {
        font-size: 16px !important;
        line-height: 20px !important;
    }

    &.error {
        border: 1.5px solid $text-error;
    }

    &.filled_disabled {
        color: $input-placeholder-color-filled_disabled !important;
    }

    &.absentValue_disabled {
        &::placeholder {
            color: $input-placeholder-color !important;
        }
    }

    &.search {
        background: #22222E;
        border-radius: 4px;
        border: none;
        padding-left: 44px;
        height: 40px;
        line-height: 18px;

        @media screen and (max-width: $Medium-md-l) {
            font-size: 16px;
            line-height: 19.5px;
            max-height: 48px;
        }
    }
    &::placeholder {
        color: $input-placeholder-color;
        font-weight: 600;
        font-size: 14px
    }
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &:disabled {
        color: $input-placeholder-color;
    }

    &::-webkit-input-placeholder {
        font-weight: 400;
        font-size: 14px;
    }

    &:-moz-placeholder {
        font-weight: 400;
        font-size: 14px;
    }

    &::-moz-placeholder {
        font-weight: 400;
        font-size: 14px;
    }

    &:-ms-input-placeholder {
        font-weight: 400;
        font-size: 14px;
    }

    & > label {
        color: #3D3D50 !important;
    }

    & > div > fieldset {
        border: 1px solid #3D3D50 !important;
    }

    & > div:hover > fieldset {
        border: 1px solid #3D3D50 !important;
    }
}

.errorInput {
    & > label {
        color: $text-error !important;
    }

    & > div > fieldset {
        border: 1px solid $text-error !important;
    }

    & > div:hover > fieldset {
        border: 1px solid $text-error !important;
    }
}

.label {
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    color: #8F96AA;
    margin-bottom: 8px;
}

.topLabel {
    position: absolute;
    top: 16px;
    left: 10px;
    font-family: $primary-font-family;
    font-weight: $font-regular;
    font-size: 14px;
    line-height: 17px;
    color: #3D3D50;
    padding: 0 5px;
    z-index: 1;
    background: $container-bg-color;
    cursor: text;
    transition: all 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;

    @media screen and (max-width: $Medium-md-l) {
        top: 20px;
    }
}

.topLabel_focus {
    @extend .topLabel;

    top: -6px;
    font-weight: $font-semibold;
    font-size: 10px;
    line-height: 12px;
}

.hint {
    font-size: 10px;
    line-height: 12px;
    text-transform: lowercase;
    color: #414158;
    margin-top: 6px;
    font-weight: normal;
    text-align: initial;
}
