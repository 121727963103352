@import "../../../styles/variables.scss";

.promotions {

    &__wrapper {
        max-width: 1920px;
        margin: 0 auto;
        background-color: #111a29;
    }

    padding: 16px 36px 40px;

    @media screen and (max-width: $Extra-large-m) {
        padding: 16px 16px 40px;
    }

    @media screen and (max-width: $Large-lg) {
        padding: 16px 24px 40px;
    }

    @media screen and (max-width: $Medium-md-l) {
        margin: 0 20vw;
        padding: 18px 0 25px;
    }

    @media screen and (max-width: $Medium-md) {
        margin: 0 14vw;
    }

    @media screen and (max-width: $Medium) {
        margin: 0 11vw;
    }

    @media screen and (max-width: $X-Small) {
        margin: 0 7vw;
        padding: 0 0 13px;
    }

    @media screen and (max-width: $X-Small-m) {
        margin: 0 2vw;
    }
}

.content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-height: 100%;
    padding: 24px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;

    @media screen and (max-width: $X-Small-m) {
        padding: 16px;
    }
}

.title {
    max-width: 265px;
    font-size: 24px;
    font-weight: $font-bold;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: left;

    @media screen and (max-width: $X-Small) {
        max-width: 245px;
        font-size: 20px;
    }

    @media screen and (max-width: $X-Small-m) {
        max-width: 195px;
        font-size: 16px;
    }
}

.btn {
    width: 120px;
    height: 36px;
    border: 1px solid $primary-text-color;
    border-radius: 4px;
    margin-top: auto;
    font-family: $primary-font-family;
    font-size: 16px;
    font-weight: $font-semibold;
    line-height: 1.25;
    color: $primary-text-color;
    cursor: pointer;

    @media screen and (max-width: $X-Small-m) {
        width: 108px;
        height: 32px;
        font-size: 13px;
    }
}

.slide {
    width: 100%;
}