@import "../../styles/variables.scss";

@mixin background {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1220;
    background: #04040Bcc;
    display: flex;
    height: 100%;
    padding: 30px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media screen and (max-width: $X-Small) {
        padding: 0;
        overflow: visible;
    }

    @media screen and (max-width: $X-Small-m) {
        padding: 0;
    }
}

.background {
    @include background;
}

.no_padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.small__container {
    padding: 24px;

    @media screen and (max-width: $X-Small-m) {
        padding: 0;
    }
}

.container {
    position: relative;
    min-height: auto;
    max-width: 720px;
    margin: auto;
    background-color: $popup-container-bg;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: $block-border-radius;
    box-shadow: 0px 4px 10px #04040B;

    .iconButton {
        width: 31px;
        height: 31px;
        padding: 0;
        position: absolute;
        right: 0px;
        top: 0px;
        cursor: pointer;
        z-index: 1;

        svg {
            fill: $secondary-gray-color;
            transition: fill .3s;
        }

        &:hover {
            svg {
                fill: $primary-white-color
            }
        }
        &.closeIconBottom {
            position: static;
            align-self: center;
            margin-top: 24px;
            margin-bottom: -16px;
        }

        .closePopup {
            margin-right: -5px;
            margin-top: -5px;
        }
        &.closeIconSingUp {
            width: 28px;
            height: 28px;
        }
    }

    .popup__simpleBackButton {
        position: absolute;
        top: 20px;
        left: 23px;
        height: auto;
        z-index: 1;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: $font-medium;
        font-size: 14px;
        line-height: 17px;
        text-transform: capitalize;
        color: #000 !important;

        img {
            margin-right: 6px;
            height: 10px;
        }
    }

    .popup__backButton_withdraw {
        position: absolute;
        top: 27px;
        left: 25px;
        z-index: 1;
        color: $primary-white-color;
        display: flex;
        padding: 15px 0 15px 25px;
        font-size: 16px;
        font-weight: $font-semibold;
        text-transform: capitalize;
        line-height: 19px;

        &:before {
            position: absolute;
            top: 18px;
            left: 0;
            content: '';
            display: inline-block;
            width: 9px;
            height: 13px;
            background: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.05469 0.789062C7.21302 1.43888 3.84635 4.36305 1.74219 5.98759L8.05469 11.8359' stroke='%23FBFBFB' stroke-width='1.57812' stroke-linecap='round'/%3E%3C/svg%3E%0A") no-repeat 0 0;
            background-size: cover;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        display: flex;
    }

    @media screen and (max-width: $X-Small-m) {
        border-radius: 0;
    }
}

.containerModal_small {
    @media screen and (max-width: $X-Small-m) {
        width: calc(100% - 48px) !important;
    }
}

.container_white_theme {
    background-color: #FFFFFF !important;
}
