@import "../../../styles/variables.scss";

.root {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.profileBalance {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    cursor: pointer;

    a {
        overflow: hidden;
        width: 100%;
        max-width: 30vw;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $primary-white-color;
        &:hover {
                text-decoration: underline;
        }

        @media screen and (max-width: $Medium-md-l) {
            max-width: 45vw;
        }

        @media screen and (max-width: $X-Small-xs) {
            max-width: 40vw;
        }
    }

    img {
        display: block;
        min-width: 16px;
        width: 16px;
    }

    @media screen and (max-width: $Large-lg-s) {
        font-size: 14px;
    }

    @media screen and (max-width: $Medium) {
        align-items: center;
    }
}

.profileBalance__title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 16px;
    line-height: 19.5px;
    color: $primary-white-color;

    @media screen and (max-width: $Large-lg-s) {
        font-size: 14px;
    }

    @media screen and (max-width: $X-Small-m) {
        gap: 8px;
    }
}

.profileBalance__description {
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: $primary-white-color;
    white-space: nowrap;

    @media screen and (max-width: $Large-lg-s) {
        font-size: 14px;
    }

    @media screen and (max-width: $Medium-md-l) {
        line-height: 20px;
    }

    @media screen and (max-width: $X-Small-m) {
        font-size: 13px;
        line-height: 16px;
    }
}

.navLinks__title {
    color: $primary-white-color;
    font-family: $primary-font-family;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &.logOut {
        text-transform: none;
    }
}

.profileLogout {
    margin-top: 6px;
}

.profileDepositSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @media screen and (max-width: $Large-lg-s) {
        gap: 16px;
    }
}

.deposit {
	overflow: hidden;
	position: relative;
	width: 150px;
	height: 46px;
	border: none;
	border-radius: 4px;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
	font-weight: $font-bold;
	line-height: 19.5px;
	color: $primary-white-color;
	background-color: $primary-red-color;
	cursor: pointer;
	transition: all .3s;
  
  &:hover {
    background-color: #e10000;
    color: $primary-text-light-color;
    box-shadow: 1px 1px 10px 7px rgba(255, 0, 0, .3);
  }
  
   &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5em;
    height: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.15) 48%, rgba(255,255,255,0.20) 70%, rgba(255,255,255,0.25) 100%);
    transform: translateX(-4em) skewX(-45deg);
    animation: move-light 3s infinite;
  }

  @media screen and (max-width: 1200px) {
    width: 125px;
  }
}

@keyframes move-light {
    from {
      transform: translateX(-100px) skewX(-45deg);
    }
    to { 
      transform: translateX(300px) skewX(-45deg);
    }
}