@import "../../../styles/variables.scss";

* {
    box-sizing: border-box;
}

.topNavContainer {
    position: fixed;
    z-index: 1210 !important;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background-color: $primary-bg-color;
    backdrop-filter: blur(50px);
    justify-content: space-between;

    @media screen and (max-width: $X-Small-m) {
        height: 48px;
    }
}

.navContainer {
    width: 100%;
    max-width: 1920px;
    display: flex;
    margin: 0 auto;
    padding: 0 25px;
    align-items: center;

    @media screen and (max-width: $Large-lg-l) {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (max-width: $Medium-md) {
        padding: 0 16px;
    }

    @media screen and (max-width: $X-Small) {
        margin-left: 0;
        padding: 0 8px;
        align-items: center;
    }
}

.logo {
    display: flex;
    cursor: pointer;
    margin-right: 28px;

    h2 {
        line-height: 0;
    }

    @media screen and (max-width: $Medium-md) {
        margin-right: 0;
    }

    .companyLogo {
        position: relative;

        & > svg {
            min-width: 105px;
            height: 44px;

            @media screen and (max-width: $Large-lg) {
                min-width: 86px;
                height: 36px;
            }

            @media screen and (max-width: $X-Small-s) {
                min-width: 68px;
                height: 30px;
            }

            @media screen and (max-width: $X-Small-xs) {
                min-width: 60px;
                height: 26px;
            }
        }
    }
}

.rightSiteButtonWrapper {
    position: absolute;
    top: 0;
    right: 48px;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    height: 100%;

    .rightSiteLeftButtonWrapper {
        display: flex;
        align-items: center;
        gap: 16px;

        @media screen and (max-width: $Large-lg) {
            margin-right: 6px;
            gap: 17px;
        }

        @media screen and (max-width: $Medium) {
            margin-right: 0;
            gap: 12px;
        }
    }

    @media screen and (max-width: $Large-lg-l) {
        right: 25px;
    }

    @media screen and (max-width: $Large-lg) {
        right: 40px;
    }

    @media screen and (max-width: $X-Small-m) {
        top: 2px;
        right: 24px;
    }
}

.navLinks {
    display: flex;
    justify-content: center;
    height: 100%;

    @media screen and (max-width: 1280px) {
        display: none;
    }
}

.navLinks li {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0 18px;

    a {
        display: inline-block;
        padding: 11px;
        border-radius: 8px;
        font-family: $primary-font-family;
        font-size: 16px;
        font-weight: 500;
        color: $primary-text-color;
        text-align: center;
        text-transform: capitalize;
        transition: all .3s;
        cursor: pointer;

        &:hover {
            background-color: #21264c;
        }
    }

    @media screen and (max-width: $Extra-large-m) {
        padding: 0;

        a {
            font-size: 14px;
            line-height: 17px;
        }
    }
}

.isWinclub {
    position: relative;
    padding-right: 2px;
    font-weight: 800 !important;
    font-style: italic;
    text-transform: uppercase !important;
    background-color: #FFE895;
    background-image: linear-gradient(180deg, #FFE895 0%, #D09235 100%);;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;

    &:before {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        content: '';
        border-radius: 8px;
        background-color: #21264c;
        transition: all .3s;
    }

    &:hover {
        &:before {
            opacity: 1;
        }
    }
}

.activeTab {
    font-weight: 700;
    color: #fff !important;
    &:after {
        position: absolute;
        top: 0;
        left: 0 !important;
        content: '';
        width: 100% !important;
        height: 4px;
        background-color: $primary-red-color;
        border-radius: 7px 6px 160px 160px / 0px 0px 150px 150px;
    }
}

@mixin hamburger {
    width: 30px;
    display: flex;
    margin-right: 25px;
    flex-direction: column;
    justify-content: center;
    z-index: 2200 !important;
    cursor: pointer;

    div {
        width: 24px;
        height: 3px;
        border-radius: 5px;
        background: #fff;
        transition: all .2s ease;
        z-index: 2200;

        @media screen and (max-width: $X-Small) {
            height: 2px;
        }
    }

    .line1 {
        width: 40px;
    }

    .line2 {
        width: 40px;
        transition: all .1s ease;
        margin: 9px 0;
    }

    .line3 {
        width: 40px;
    }

    @media screen and (max-width: $Medium) {
        margin-right: 23px;
    }

    @media screen and (max-width: $X-Small) {
        min-width: 34px;
        width: auto;
        margin: 0 10px 0 0;


        .line1,
        .line2,
        .line3 {
            width: 32px;
        }

        .line2 {
            margin: 8px 0;
        }
    }
}

.hamburger {
    @include hamburger;
    display: none;

    @media screen and (max-width: 1280px) {
        display: flex;
    }

    @media screen and (max-width: $X-Small-xs) {
        margin: 0 5px 0 0;
    }
}

@media screen and (max-width: $Large-lg-l) {
    .hamburger {
        transition: all 0.5s ease;
    }

    .navLinks li {
        opacity: 1 !important;

        &:nth-child(1) {
            transition: all 0.5s ease 0.2s;
        }

        &:nth-child(2) {
            transition: all 0.5s ease 0.4s;
        }

        &:nth-child(3) {
            transition: all 0.5s ease 0.6s;
        }

        &:nth-child(4) {
            transition: all 0.5s ease 0.7s;
        }

        &:nth-child(5) {
            transition: all 0.5s ease 0.8s;
        }
    }

    li.fade {
        opacity: 0;
    }
}

@mixin toggle {
    border-radius: 5px;
    z-index: 2200;

    .line1 {
        width: 35px;
        height: 3px;
        background-color: #fff;
        transform: rotate(-45deg) translate(-9px,9px);

        @media screen and (max-width: $X-Small) {
            width: 30px;
            height: 2px;
            background-color: #fff;

            transform: rotate(-45deg) translate(-9px,9px);
        }
    }

    .line2 {
        width: 0;
        transition: all 0s ease;
        -webkit-transition: all 0s ease;
        -moz-transition: all 0s ease;
        -ms-transition: all 0s ease;
        -o-transition: all 0s ease;
    }

    .line3 {
        width: 35px;
        height: 3px;
        background-color: #fff;
        transform: rotate(45deg) translate(-8px,-8px);

        @media screen and (max-width: $X-Small) {
            width: 30px;
            height: 2px;
            background-color: #fff;

            transform: rotate(45deg) translate(-5px,-5px);
        }
    }
}

.toggle {
  @include toggle
}

// For new red design

.formSingIn {
    display: flex;
    input {
      -webkit-text-fill-color: #BDBDBD;
      &:hover {
          -webkit-text-fill-color: #BDBDBD;
      }
    }
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: #BDBDBD;
    }
}

.authSectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.forgotPasswordButton {
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 11px;
    line-height: 125%;
    color: #BDBDBD;
    cursor: pointer;
}

.loginButtons {
    display: grid;
    grid-template-columns: repeat(2, 130px);
    align-items: center;
    gap: 8px;

    @media screen and (max-width: $X-Small-m) {
        grid-template-columns: repeat(2, 80px);
    }
}

.authInput {
    margin: 0 8px 6px 0;

    &:last-of-type {
        margin-right: 0;
    }
}

.gamification {
    position: relative;

    &__title {
        position: relative;
        padding: 11px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: $font-medium;
        line-height: 20px;
        color: $primary-text-light-color;
        transition: all .3s;
        cursor: pointer;

        &:hover {
            border-radius: 8px;
            background-color: #21264c;
        }
    }
}

.right_site_button_wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: auto;

    & > div {
        height: 100%;
    }
}

.right_site_rightButton_wrapper {
    height: 100%;
}

.right_site_leftButton_wrapper {
    display: flex;
    gap: 8px;
}

.navbar__right {
    display: flex;
    align-items: center;
    margin-right: 10px;

    &__buttons {
        display: flex;
        margin-right: 16px;

        a {
            margin-right: 8px;
        }

        a,
        .navbar__right__support__button {
            padding: 8px;
            border-radius: $element-border-radius;
            transition: all 0.3s;

            &:hover {
                background-color: #21264C;
            }
        }

        img {
            cursor: pointer;
        }
    }
}

.btn_facebook {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    width: 55px;
    height: 68px;
    background: #1877F2;
    border-radius: 2px;
    font-weight: 600;
    font-size: 13px;
    line-height: 125%;
    color: #FFFFFF;
    cursor: pointer;

    svg {
        margin-bottom: 8px
    }

    p {
        font-weight: 600;
        font-size: 11px;
        line-height: 125%;
    }

    @media screen and (max-width: $Large-lg-l) {
        flex-direction: row;
        width: 120px;
        height: 40px;
        padding: 6px 10px;
        svg {
                margin-bottom: 0px;
                margin-right: 8px;
            }
        p {
            font-size: 16px;
        }
    }

    @media screen and (max-width: $Medium) {
            display: none;
        }
}

.frozen__account__popup {
    position: relative;
}
