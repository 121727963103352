@import '../../styles/variables';

.wrapper {
    width: 100%;
    position: relative;

    .errorText {
        color: $text-error;
        margin-top: 4px;
        font-family: $primary-font-family;
        font-size: 12px;
        line-height: 18px;
        padding-left: 8px;
        text-align: start;
    }
    img {
        position: absolute;
        left: 16px;
        top: 14px;
        height: 20px;
    }
}

.wrapper.small {
    width: 136px;
}

.wrapper.medium {
    width: 288px;
}

.select {
    width: 100%;
    padding: 12px 48px 12.5px 12px;
    background: #33353D;
    border-radius: 8px;
    font-family: $primary-font-family;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $primary-white-color;
    opacity: 1;
    outline: none;
    border: 1px solid $input-border-color;;
    border-radius: $input-border-radius;
    cursor: pointer;
    font-weight: 400;

    //     Add 3 dots if width of text more width select
    text-overflow: ellipsis;
    white-space: nowrap;

    &::placeholder {
        color: $input-placeholder-color;
    }
    &.error {
        border: 1.5px solid $text-error;;
    }
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance:none;
    background: url('./arrow_active.svg') no-repeat;
    background-position-x: calc(100% - 21px);
    background-position-y: 20px;
    background-size: 10px;

    option {
        background: $container-bg-color;
        color: $primary-white-color;
    }
    option:checked,
    option:hover {
        background: #1B1B27;
    }

    &.iconFlag {
        padding-left: 44px;
    }

    &.placeholder {
        color: $input-placeholder-color;
    }

    &.newSignUpForm {
        background: #F5F6FA;
        border: 1px solid #9CA4BF !important;
        padding: 16px 0 16px 12px;
        height: 48px;
        border-radius: 4px !important;
    }

    svg {
        color: $svg-color-inactive;
        :hover {
            color: $svg-color-inactive-hover
        }
    }

    @media screen and (max-width: $Medium-md-l) {
        padding: 18px 48px 17px 12px;
        font-size: 16px;
        line-height: 19.5px;
        background-position-y: 24px;
        background-position-x: calc(100% - 12px);
    }
}

.select_disabled {
    @extend .select;

    background-position-x: calc(100% - 14px);
    background-position-y: 18px;
    background: url('./arrow.svg') no-repeat;

    &.newSignUpForm {
        background-color: #F5F6FA;
    }
}

.option:hover {
    background: #1B1B27 !important;
}

.label {
    width: 100%;
    text-align: start;
    font-family: $primary-font-family;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    font-weight: $font-semibold;
    color: #8F96AA;
    margin-bottom: 8px;
    display: inline-block;
}

.topLabel {
    position: absolute;
    top: -6px;
    left: 10px;
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 10px;
    line-height: 12px;
    text-transform: capitalize;
    color: #3D3D50;
    padding: 0 5px;
    z-index: 1;
    background: $container-bg-color;
}

.errorText {

}
