@import "../../styles/variables.scss";

@mixin facebookSignUp {
    padding: 12px;
    border: 1px solid #1877F2;
    border-radius: $element-border-radius;
    color: #FFFFFF;
    background: #1877F2;
    box-sizing: border-box;
    &:hover {
        border: 1px solid #1877F2 !important;
        background: #1877F2 !important;
        box-shadow: none !important;
    }
}

.button {
    @media screen and (max-width: $Large-lg) {
        height: 48px !important;
    }
}
