.close__wrapper {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    svg {
        width: 100%;
        height: 100%;
    }
}