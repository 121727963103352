@import "../../styles/variables.scss";

.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    &.newSignUpForm {
        align-items: flex-start;
    }
}

.checkBox {
    width: 20px;
    height: 20px;
    position: absolute;
    margin: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.checkbox__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 18px;
    height: 18px;
    border: 1px solid $input-border-color;
    box-sizing: border-box;
    border-radius: 2px;
    color: $primary-white-color;

    @media screen and (max-width: $Medium-md-l) {
        width: 24px;
        height: 24px;

        svg {
            font-size: 13px !important;
        }
    }
}

.checkbox__container__checked {
    border: 1px solid $primary-white-color;
    color: $primary-white-color;
}

.label {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-transform: capitalize;
    color: $primary-white-color;
    cursor: pointer;
    padding-left: 12px;
    width: 100%;
    color: #BDBDBD;

    &::first-letter {
        text-transform: capitalize;
    }

    @media screen and (max-width: $Medium-md) {
        font-size: 14px;
        line-height: 17px;
    }
}

.errorText {

    @media screen and (max-width: $Medium-md-l) {
        padding-left: 16px;
    }
}

.remember__checkbox {
    align-items: center !important;
}