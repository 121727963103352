@import "../../../styles/variables";

.resetPassword {
    width: 100%;
    max-width: 400px;
    padding: 48px 16px 38px;
    position: relative;
    background-color: $popup-container-light;
    box-shadow: $popup-container-shadow;

    & > button {
        top: 12px !important;
        right: 12px !important;

        svg {
            width: 16px !important;
            height: 16px !important;
            fill: $popup-close-icon !important;
            stroke: $popup-close-icon !important;
        }
    }

    @media screen and (max-width: $Medium-md-l) {
        max-width: 466px;
        padding: 40px 24px;
    }

    @media screen and (max-width: $X-Small-m) {
        width: 100%;
        max-width: none;
        height: 100vh;
        justify-content: center;
        padding: 101px 16px 48px;
        border-radius: 0;

        & > button {
            top: 16px !important;
            right: 16px !important;
            height: 16px !important;
            width: 16px !important;
            color: $primary-text-color !important;

            svg {
                width: 16px !important;
                height: 16px !important;
            }

            &:before {
                background: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.57248 0.164487C7.91742 -0.101829 8.41295 -0.0380898 8.67926 0.306854C8.94558 0.651797 8.88184 1.14732 8.5369 1.41364L7.57248 0.164487ZM1.74219 5.98759L1.20592 6.56642L0.521998 5.93278L1.25998 5.36302L1.74219 5.98759ZM8.59095 11.2571C8.91063 11.5533 8.92968 12.0525 8.63351 12.3722C8.33734 12.6919 7.8381 12.7109 7.51842 12.4148L8.59095 11.2571ZM8.5369 1.41364C8.13299 1.72547 7.10365 2.59961 5.87911 3.62723C4.67043 4.64155 3.29101 5.78867 2.2244 6.61217L1.25998 5.36302C2.29753 4.56197 3.65353 3.43473 4.86464 2.41838C6.05989 1.41533 7.13471 0.502467 7.57248 0.164487L8.5369 1.41364ZM2.27845 5.40877L8.59095 11.2571L7.51842 12.4148L1.20592 6.56642L2.27845 5.40877Z' fill='%231A1818'/%3E%3C/svg%3E%0A") no-repeat 0 0 !important;
            }
        }
    }

    .form {
        input {
            height: 48px !important;
            padding: 12px 16px !important;
            border-radius: 4px !important;
            border: 1px solid #9CA4BF !important;
            background: #F5F6FA !important;

            &:focus {
                border: 1px solid #73788C !important;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                transition: background-color 50000000s ease-in-out 0s;
                -webkit-box-shadow: 0 0 0 1000px $light-bg-color inset;
                caret-color: $primary-text-dark-color !important;
                -webkit-text-fill-color: $primary-text-dark-color !important;
            }

            &::placeholder {
                font-size: 13px !important;
                color: #999DAD !important;
            }

            & + button {
                background: none;
                svg path {
                    stroke: #898996;
                }
            }
        }

        .errorText {
            margin-top: -16px;
            margin-bottom: 14px;
            color: $text-error;
            font-family: $primary-font-family;
            font-size: 11px;
            line-height: 13px;
            text-align: start;
        }
    }
}

.resetPassword__icon {
    svg {
        color: transparent;
    }
    margin-bottom: 14px;

    @media screen and (max-width: $X-Small-m) {
        margin-top: -45px;
    }
}

.title {
    margin-bottom: 8px;
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: $font-semibold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $popup-title;
}

.description {
    font-family: $primary-font-family;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 16px;
    max-width: 180px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;

    label {
        background-color: $light-bg-color !important;
    }

    button {
        right: 8px !important;

        svg {
            width: 18px !important;
            height: 18px !important;
            path {
                stroke: $primary-dark-color;
            }
        }
    }

    p {
        color: #414158;
    }

    @media screen and (max-width: $X-Small-m) {
        flex-grow: 1;
        max-width: 100%;
    }
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_apply {
    width: 160px;
    height: 42px;
    border: 1px solid transparent;
    border-radius: $popup-container-br;
    padding: 12px 23px;
    font-family: $primary-font-family;
    font-size: 14px;
    font-weight: $font-semibold;
    line-height: 17.5px;
    color: $primary-text-light-color;
    background-color: $popup-btn-dark;
    box-shadow: 0px 16px 32px -3px rgba(56, 68, 90, 0.15), 0px 4px 8px -3px rgba(56, 68, 90, 0.15);
    transition: all .3s;
    cursor: pointer;

    &:hover {
        border: 1px solid $popup-btn-dark;
        color: $light-bg-color;
        background: #283040;
        box-shadow: none;
    }

    @media screen and (max-width: $X-Small-m) {
        width: 100%;
    }
}

.button_cancel {
    width: 160px;
    height: 42px;
    border: none;
    border-radius: $popup-container-br;
    font-family: $primary-font-family;
    font-size: 13px;
    font-weight: $font-medium;
    line-height: 16px;
    color: $primary-text-color;
    background-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: $primary-text-color;
    transition: all .3s;

    &:hover {
        text-decoration-color: transparent;
    }
}
