@import "../../../styles/variables.scss";
@import "../../../styles/reactAnimation";

.forgotPassword {
    width: 100%;
    max-width: 400px;
    padding: 48px 24px 38px;

    & > div:first-of-type {
        top: 16px;
        right: 16px;
        width: 16px !important;
        height: 16px !important;

        @extend .close-icon-animation;
        -webkit-animation: none !important;

        svg {
            stroke: $popup-close-icon;
        }

        & > span {
            color: transparent;
            background: transparent !important;
        }

        &:hover,
        &:active {
            background: transparent;

            svg {
                stroke: $popup-close-icon-hover;
            }
        }

        @media (hover: none) {
            background: transparent !important;
        }
    }

    @media screen and (max-width: $Medium-md-l) {
        padding: 40px 40px 60px;
    }

    @media screen and (max-width: $Large-lg) {
        max-width: 466px;
    }

    @media screen and (max-width: $X-Small-m) {
        width: 100%;
        max-width: none;
        height: 100vh;
        padding: 49px 24px 48px;
        border-radius: 0;

        & > button {
            top: 17px !important;
        }
    }
}

.title {
    margin: 19px 0 7px;
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: $font-semibold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $secondary-dark-color;

    @media screen and (max-width: $Medium-md-l) {
        padding: 16px 0 8px;
    }

    @media screen and (max-width: $X-Small-m) {
        margin: 0;
        padding: 14px 0 8px;
        line-height: 23px;
    }
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;

    input {
        height: 48px;
        border: 1px solid #9CA4BF !important;
        background: #f5f6fa !important;

        &:focus {
            border: 1px solid #73788C !important;
        }
    }

    @media screen and (max-width: $X-Small-m) {
        max-width: none;
        flex-grow: 1;

        input {
            height: 56px;
        }
    }
}

.message {
    margin: 0 auto 13px;
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: $secondary-dark-color;

    @media screen and (max-width: $X-Small-m) {
        font-size: 16px;
        line-height: 24px;
    }
}

.buttons {
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;
    color: #fff !important;

    button {
        font-size: 14px !important;
        line-height: 17px !important;
        width: 160px !important;
        height: 42px !important;
        margin: auto;
        border-radius: 8px !important;
        box-shadow: 0px 16px 32px -3px rgba(56, 68, 90, 0.15), 0px 4px 8px -3px rgba(56, 68, 90, 0.15);

        &:hover {
            background-color: #283040 !important;
        }

        &:disabled {
            background: #EBEDEF !important;
            box-shadow: none !important;
            cursor: default;
        }
    }

    &.reset {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 16px;
        gap: 8px;

        button {
            width: 100% !important;
            height: 48px !important;
            background-color: $popup-btn-dark !important;
            color: #fff !important;

            &:hover {
                box-shadow: 0px 4px 4px 0px rgba(149, 149, 164, 0.60);
                background-color: #283040 !important;
            }
        }

        .retry {
            width: 160px;
            font-family: $primary-font-family;
            font-size: 13px;
            font-weight: $font-medium;
            line-height: 16.25px;
            text-decoration: underline;
            color: $primary-text-dark-color !important;;
            background-color: transparent !important;
            cursor: pointer;
            transition: all .3s;
            box-shadow: none;

            &:hover {
                box-shadow: none;
                background-color: transparent !important;
            }
        }
    }
}
