@import '../../styles/variables';

.wrapper {
    width: 100%;
    position: relative;

    .errorText {
        color: $text-error;
        margin-top: 4px;
        font-family: $primary-font-family;
        font-size: 12px;
        line-height: 18px;
        padding-left: 8px;
        text-align: start;
    }
}

.wrapper.tiny {
    width: 55px;
}

.wrapper.small {
    width: 80px;
}

.wrapper.medium {
    width: 288px;
}

.label {
    font-family: $primary-font-family;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    font-weight: $font-semibold;
    color: #8F96AA;
    margin-bottom: 8px;
    display: inline-block;
}

.wrapperContent {
//     box-shadow: 0px 4px 10px #04040B;
//     border-radius: 0 0 4px 4px;
//     border: 1px solid $input-border-color;
    border-top-color: transparent;
    box-sizing: border-box;
    padding: 0 16px 3px 15px;
    margin-top: -6px;
    display: none;
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 10000000;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        border-radius: 0 0 8px 8px;
        top: 39px;
    &.iconFlag {
        width: 340px;
//         border: 1px solid $input-border-color;
        margin-top: 0;
        padding: 0 8px 3px 0;
        @media screen and (max-width: 481px) {
            width: 380px;
        }
    }
    &.show {
        display: block;
    }
    .wrapperSearch {
        margin: 22px 0 16px;
        padding: 0 0 0 16px;
        &.checkBox {
            padding: 0
        }

        @media screen and (max-width: $Medium-md-l) {
            margin: 22px 0 20px;
        }
    }

    .wrapperSearchLine {
        input {
            height: 28px;
            background: #F8F7FA;
            border-radius: 40px;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #BDBDBD;
            font-family: $primary-font-family;;
            font-style: normal;
                text-transform: capitalize;

                &::placeholder {
                                color: #BDBDBD;
                }
        }
        svg {
            font-size: 16px;
            path {
                fill: #BDBDBD;
            }
        }
    }
}

.btn {
    text-align: start;
    position: relative;
    z-index: 100000000;
    img {
        position: absolute;
        height: 16px;
    }
}

.divider {
    background: #4B4F5C;
    border-radius: 8px;
    margin: 11px 0 20px;
    height: 1px;

    @media screen and (max-width: $Medium-md-l) {
        margin: 15px 0 16px;
    }
}
.ul {
    max-height: 254px;
    overflow: auto;

    //@include scrollVertical();

    &::-webkit-scrollbar {
        width: 8px;
        height: 20px;
    }

        &::-webkit-scrollbar-track {
            background-color: #F5F3F3;
            border-radius: 1vw;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #DEDEE0;
            border-radius: 100vw;
        }

}
.li {
    margin-bottom: 12px;
}
.active {
    background: #E73843;
    color: #fff !important;
}

.errorText {

}

.codePhone {
    line-height: 12px;
    padding-left: 20px;
    display: inline-block;
    color: #414158
}

li.iconFlag {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;
    margin-bottom: 0;
    color: #000;
    padding: 0 12px 0 16px;
    img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
    .text {
        margin-right: 24px;
        font-size: 14px;
    }
    .name {
        text-align: start;
        font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
    }
}

.selectLine {
        font-size: 13px !important;
        line-height: 16px !important;
        background-position-x: calc(100% - 3px) !important;
        background-position-y: 15px !important;
        border: none !important;
        border-bottom: 1px solid $primaryBorderLine !important;
        border-radius: 0 !important;

        &.newSignUpForm {
            border: 0.6px solid #9CA4BF;
            background: #F5F6FA;
        }

        &.error {
            border: 1px solid $text-error !important;
        }
 }

.btnLine {
        display: flex;
        align-items: center;
        z-index: 100;
        background-position-x: 100% !important;
}

.btnDisabled {
    cursor: initial;
}

.codePhoneLine {
    position: relative;
    line-height: 16px;
    color: #898996;
    font-weight: 500;

    //&:after {
    //    position: absolute;
    //    top: 6px;
    //    right: -24px;
    //    content: '';
    //    width: 0;
    //    height: 0;
    //    border-style: solid;
    //    border-width: 5.5px 5px 0 5px;
    //    border-color: #495057 transparent transparent transparent;
    //}
}
