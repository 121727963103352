@import "../../styles/variables";

.btn {
    text-transform: none;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
    line-height: 20px;
    font-family: inherit;
    font-weight: 600;
    padding: 10px 32px;
    outline: none;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: initial;
    color: #FFFFFF;
    transition: all .3s;

    // For dark theme GREEN / RED
    font-family: $primary-font-family !important;
    font-weight: $font-semibold !important;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
    color: #000;

    @media screen and (max-width: $X-Small-m) {
        height: 29px;
        font-size: 10px;
        line-height: 1.25;
    }
}

.btnPrimary {
    background: $btn-primary-bg-color;
    border: 1px solid $btn-primary-bg-color;
}

.btnPrimary:hover {
    background: $btn-primary-hover-bg-color;
    border: 1px solid $btn-primary-hover-border-color;
}

.btnSecondary {
    background: transparent;
    border: 1px solid $btn-secondary-border-color;
}

.btnSecondary:hover {
    background: $btn-secondary-hover-bg-color;
    border: 1px solid $btn-secondary-hover-border-color;
}

.btnPrimaryGreen {
    border: 1px solid $btn-primary-bg-color-green;
    background: $btn-primary-bg-color-green;
}

.btnPrimaryGreen:hover {
    border: 1px solid $btn-primary-bg-color-green-hover;
    background: $btn-primary-bg-color-green-hover;
}

@media (hover: none) {
    .btnPrimaryGreen:hover {
        border: 1px solid $btn-primary-bg-color-green;
        background: $btn-primary-bg-color-green;
    }
}

.btnPrimaryGreen:disabled {
    border: 1px solid #C8F0A8;
    background: #C8F0A8 !important;
    cursor: default;
}

.btnPrimaryGreenOutlined {
    border: 1px solid $btn-primary-bg-color-green;
    color: $primary-text-color;
}

.btnPrimaryGreenOutlined:hover {
    background: $btn-primary-green-outlined-hover;
    border: 1px solid $btn-primary-bg-color-green;
}

@media (hover: none) {
    .btnPrimaryGreenOutlined:hover {
        background: transparent !important;
        border: 1px solid $btn-primary-bg-color-green;
    }
}

.btnPrimaryMintGreen {
    background: #61DEBE;
    color: #2D2A26;
}

.btnPrimaryMintGreen:hover {
    background: #2FD3A9 !important;
}

.btnPrimaryMintGreen:disabled {
    background: #B8F1E2;
}

.btnPrimaryBlue {
    background: #1877F2;
        color: white;
}

.btnPrimaryBlack {
    background: #38445A;
    color: white;
}

.btnPrimaryBlack:hover {
    background: #283040;
}

.btnPrimaryBlack:disabled {
    background: #EBEDEF !important;
    cursor: default;
}

.btnSecondaryWhite {
    background: white;
    color: $primary-red-color;
}

.btnSecondaryWhite:hover {
    background: #F0F0F0;
}

.btnPrimaryRed {
    background: #E73843;
    color: $primary-white-color;
}

.btnPrimaryRed:hover {
    background: #E10000;
}

.btnPrimaryRed:disabled {
    background: #F5AFB3 !important;
}

.forgotPasswordBtn, .smallModal {
    text-transform: capitalize !important;
    background-color: #38445A !important;
    color: #fff;

    &:disabled {
        background-color: #EBEDEF !important;
    }
}

.btnIcon {
    display: flex;
    align-items: center;
}

.button {
    width: 120px !important;
    height: 40px !important;
    font-size: 16px !important;
    color: #FBFBFB !important;
    background-color: #E73843 !important;
    text-transform: capitalize !important;

    @media screen and (max-width: $Large-lg) {
        height: 48px !important;
        width: 140px !important;
    }

    @media screen and (max-width: $X-Small-xs) {
        width: 100% !important;
    }
}

.depositButton {
    background-color: $primary-red-color;
    height: 100%;
    font-family: $primary-font-family;
    font-weight: $font-bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $primary-text-light-color;

    @media screen and (max-width: $Large-lg-s) {
        width: 160px !important;
    }

    @media screen and (max-width: $Medium-md-l) {
        width: 130px !important;
        height: 40px !important;
    }

    @media screen and (max-width: $X-Small-m) {
        font-weight: $font-semibold !important;
        font-size: 11px !important;
        line-height: 125% !important;
        width: 96px !important;
        height: 30px !important;
    }
}