@import "../../../styles/variables.scss";

.promo {

    &__container {
        max-width: 736px !important;
        padding-bottom: 32px;
        background-color: #1c232f;

        & > div:first-of-type {
            width: 16px;
            height: 16px;
            svg {
                path {
                    stroke: $primary-text-light-color;
                }
            }
        }

        @media screen and (max-width: $X-Small-m) {
            min-height: 100%;
            padding: 0;
        }
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        @media screen and (max-width: $X-Small-m) {
            padding-top: 64px;
        }
    }

    &__title,
    &__title__mobile {
        padding: 16px 15px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: $font-bold;
        text-align: left;
        text-transform: uppercase;
        color: $primary-text-light-color;

        @media screen and (max-width: $X-Small-m) {
            padding: 16px 15px 12px;
        }
    }

    &__media {
        margin-bottom: 10px;

        @media screen and (max-width: $X-Small-m) {
            overflow: hidden;
            max-height: 200px;
        }
    }

    &__img {
        max-width: 100%;
        width: 100%;
        height: 296px;
        object-fit: cover;

        @media screen and (max-width: $Medium) {
            height: 40vw;
        }
    }

    &__content {
        height: 100%;
        padding: 3px 24px 24px;
        font-size: 13px;
        line-height: 1.846;

        &__inner {
            overflow-y: auto;
            height: 336px;
            padding: 8px 15px 0 0;

            &::-webkit-scrollbar {
                width: 8px;
            }
        
            &::-webkit-scrollbar-track {
                background-color: #2B3547;
                border-radius: 8px;
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: #4B5C7A;
                border-radius: 8px;
            }

            @media screen and (max-width: $Medium-md) {
                padding: 3px 15px 0 0;
            }

            @media screen and (max-width: $X-Small-m) {
                height: 400px;
                max-height: 70%;
                padding: 0 15px 40px 0;
            }
        }

        @media screen and (max-width: $X-Small-m) {
            padding: 3px 16px 24px;
        }
    }

    &__text {
        h3 {
            margin-bottom: 6px;
            font-size: 18px;
            line-height: 24px;
            font-weight: $font-semibold;

            @media screen and (max-width: $X-Small-m) {
                font-size: 14px;
                line-height: 17px;
            }
        }

        h4 {
            font-size: 16px;
            line-height: 20px;
        }

        p {
            min-height: 24px;
            font-size: 13px;
            line-height: 24px;

            @media screen and (max-width: $X-Small-m) {
                font-size: 12px;
            }
        }

        a {
            font-weight: $font-semibold;
            color: $primary-red-color;
            text-decoration: underline;
            text-decoration-color: inherit;
            transition: all .3s;

            &:hover {
                text-decoration-color: transparent;
            }
        }

        strong {
            font-weight: 600;
            line-height: 17px;
            text-transform: uppercase;
        }

        ul {
            padding-left: 10px;
        }

        li {
            position: relative;
            padding-left: 20px;

            &:before {
                position: absolute;
                top: 9px;
                left: 0;
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #fff;
            }
        }
    }

    &__btns {
        position: relative;
        z-index: 1;
        margin-bottom: 15px;

        @media screen and (max-width: $X-Small) {
            display: flex;
            align-items: stretch;
            flex-wrap: nowrap;
        }
    }

    &__btn {
        position: relative;
        height: 32px;
        margin-right: 8px;
        padding: 0 20px;
        border-radius: 8px;
        font-family: $primary-font-family;
        font-size: 13px;
        font-weight: $font-semibold;
        line-height: 1.2;
        color: $primary-text-light-color;
        background-color: #38445a;
        cursor: pointer;

        &:last-of-type {
            margin-right: 0;
        }

        &__active {
            box-shadow: 0px 0px 12px 0px rgba(65, 73, 126, .80);

            &:before {
                position: absolute;
                top: -1px;
                left: -1px;
                z-index: -1;
                content: '';
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                border-radius: 8px;
                background: linear-gradient(180deg, #FFE895, #D09235) border-box;
            }
        }

        @media screen and (max-width: $X-Small) {
            height: auto;
            padding: 8px 18px;
        }

        @media screen and (max-width: $X-Small-m) {
            padding: 8px 10px;
        }
    }

    &__link {
        position: relative;
        padding-left: 12px;
        font-size: 13px;
        line-height: 1.846;
        text-decoration: underline;
        text-decoration-color: $primary-text-light-color;
        color: $primary-text-light-color;
        transition: all .3s;

        &:before {
            position: absolute;
            top: 8px;
            left: 3px;
            content: '';
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background-color: $primary-text-light-color;
        }

        &:hover {
            text-decoration-color: transparent;
        }
    }
}

/* Countdown styles*/
.promotions__popup__countdown__wrapper {
    margin-top: 8px;
    margin-bottom: 16px;
    display: flex;
    gap: 10px;
}

.promotions__popup__countdown__num {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid;

    font-size: 23px;
    font-weight: $font-semibold;

}

.days {
    border-color: #56EDAD;
}

.hours {
    border-color: #04F803;
}

.minutes {
    border-color: #5F65FF;
}

.seconds {
    border-color: #FF75F6;
}

.promotions__popup__countdown__text {
    padding-top: 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: $font-regular;
    text-align: center;
}