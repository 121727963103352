@import "../../../styles/variables.scss";

.container {
    max-width: 400px;
    padding: 48px 23px 24px;
    background-color: $popup-container-light;

    @media screen and (max-width: $X-Small-m) {
        max-width: 100%;
        height: 100%;
        padding: 56px 16px 24px;
    }

    button {
        width: 160px;
        height: 42px !important;
        border-radius: 8px !important;
        font-size: 14px !important;
        box-shadow: 0px 16px 32px -3px rgba(56, 68, 90, 0.15), 0px 4px 8px -3px rgba(56, 68, 90, 0.15);
    
        @media screen and (max-width: $X-Small-m) {
            width: 100%;
        }
    }
}

.icon {
    margin-bottom: 14px;
}

.title {
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: $font-bold;
    line-height: 24px;
    color: $popup-title;
}

.leading {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: $font-medium;
    line-height: 18px;
    text-align: center;
    color: $popup-title;

    @media screen and (max-width: $X-Small-m) {
        max-width: 305px;
        margin-bottom: 24px;
    }
}