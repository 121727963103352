//////////////////////////
/// Close icon animation
.close-icon-animation {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent !important;
    cursor: pointer;

    & > span,
    svg  {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        -webkit-transition: transform .5s cubic-bezier(.36,.66,.04,1);
            -ms-transition: transform .5s cubic-bezier(.36,.66,.04,1);
                transition: transform .5s cubic-bezier(.36,.66,.04,1);
    }


    &:hover,
    &:active {
        -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
                transform: rotate(-90deg);
        stroke: $popup-close-icon-hover;
    }
}