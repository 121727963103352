@import "../../styles/variables.scss";

.layoutPromo {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding-top: 100px;

    @media screen and (max-width: $Medium-md-l) {
                    padding-top: 82px;
            }
    @media screen and (max-width: $Medium-md) {
                    padding-top: 0;
            }
    @media screen and (max-width: 480px) {
                        padding-top: 62px;
                }
}

.layout_general {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    padding-top: 64px;
    background-color: $header-dark-bg-color;
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;

    &.afterSport,
    &.isFirefox {
      scroll-snap-type: none;
    }

    @media screen and (max-width: $Medium-md) {
      scroll-snap-type: none !important;
    }

    @media screen and (max-width: $X-Small-m) {
        padding-top: 48px;
    }
}

.mock_app {
    background-color: none;
}

main {
    overflow-x: hidden;
}