@import "../../../styles/variables";

.header {
    z-index: 1210 !important;
    display: flex;
}

.navbarButton {
    button {
        width: 95px;
    }

    @media screen and (max-width: $Large-lg) {
        button {
            width: 100px;
            height: 48px;
        }
    }

    @media screen and (max-width: $Medium) {
        button {
            width: 40px;
            height: 40px;
            min-width: 0 !important;
            margin-top: -4px;
            padding: 0;

            span {
                margin: 0;
            }

            svg {
                margin-left: 3px;
                font-size: 26px !important;
            }
        }
    }
}
