@import "../../../styles/variables.scss";

.wrapper {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 0;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-animation: none !important;

    svg {
        -webkit-transition: transform .5s cubic-bezier(.36,.66,.04,1);
            -ms-transition: transform .5s cubic-bezier(.36,.66,.04,1);
                transition: transform .5s cubic-bezier(.36,.66,.04,1);
        stroke: $popup-close-icon;
        overflow: hidden;
        cursor: pointer;
    }

    & > span {
        color: transparent;
        background: transparent !important;
    }

    svg:hover,
    svg:active {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);

        stroke: $popup-close-icon-hover;
    }
}