/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

/*Reset*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

#root {
  height: 100%;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
   content: "";
   content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  padding: 0;
  border: none;
  background: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/*Common Styles*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #696969;
}

body, * {
  scrollbar-width: thin;
  scrollbar-color:  #696969 #020105;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #FBFBFB;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  background: #05070A;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

h1 {
  font-size: 48px;
}

h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.015em;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 18px;
}

.flex {
  display: flex;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-multi-carousel-dot--active button {
  background: #AE8420 !important;
  border: 1px solid #AE8420 !important;
}

.react-multiple-carousel__arrow {
  background: transparent !important;
  margin-bottom: 32px !important;
}

@media screen and (max-width: 414px) {
  .react-multiple-carousel__arrow {
    min-width: initial !important;
    width: 20px;
  }
}

.react-multiple-carousel__arrow--left {
  left: -14px !important;
}
@media screen and (max-width: 1199px) {
  .react-multiple-carousel__arrow--left {
    left: 0px !important;
  }
}

@media screen and (max-width: 700px) {
  .react-multiple-carousel__arrow--left {
    left: -10px !important;
  }
}

@media screen and (max-width: 414px) {
  .react-multiple-carousel__arrow--left {
    left: 10px !important;
  }
}


.react-multiple-carousel__arrow--right {
  right: -14px !important;
}
@media screen and (max-width: 1199px) {
  .react-multiple-carousel__arrow--right {
    right: 0 !important;
  }
}

@media screen and (max-width: 700px) {
  .react-multiple-carousel__arrow--right {
    right: -10px !important;
  }
}

@media screen and (max-width: 414px) {
  .react-multiple-carousel__arrow--right {
    right: 10px !important;
  }
}

.react-multi-carousel-dot button {
  border: 1px solid #E73843 !important;
}

.react-multi-carousel-dot--active button {
  background: #E73843 !important;
  border: 1px solid #E73843 !important;
}

@media screen and (max-width: 1024px) {
  .react-multi-carousel-dot button {
    margin-right: 9px !important;
  }
}


@media screen and (max-width: 899px) {
    .react-multi-carousel-dot button {
    margin-right: 13px !important;
  }
}

.react-multi-carousel-dot button:hover:active {
  background: #E73843 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  caret-color: #404040;
  -webkit-text-fill-color: #05070A;
  /*This is trick reset autofill background*/
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  /*transition: background-color 50000000s ease-in-out 0s;*/
  color: #05070A;
  background: #ffffff;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: textfield;
}


/*For Edge & IE hide auto-fill*/
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

/*For Safari*/
input[type=password]::-webkit-contacts-auto-fill-button,
input[type=password]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  position: absolute;
  pointer-events: none;
  right: 0;
  width: 0;
  margin: 0;
  opacity: 0;
}

@keyframes progress  {
  0% {
      width: 10%;
  }
  50% {
      width: 50%;
  }
  100% {
      width: 100%;
  }
}

.swiper-container {
  width: 100%;
  min-width: 0;
  max-width: 100%;
  
  padding-bottom: 10px !important;
}

@media screen and (max-width: 1600px) {
  .swiper-container {
    overflow: initial !important;
  }
}

.swiper-wrapper {
  max-width: 100vw;
  max-height: 100%;
  height: 100%;
  display: flex;
}

.swiper-slide {
  width: 100%;
  display: flex;
  min-width: 0;
  min-height: 13.5vw;
  flex-shrink: 0;
  border-radius: 8px;
}

@media screen and (max-width: 1600px) {
  .swiper-slide {
    min-height: 13.8vw;
  }
}

@media screen and (max-width: 1366px) {
  .swiper-slide {
    min-height: 13.6vw;
  }
}

@media screen and (max-width: 1300px) {
  .swiper-slide {
    min-height: 20.5vw;
  }
  .swiper-container {
    overflow: hidden !important;
  }
}

@media screen and (max-width: 1024px) {
  .swiper-slide {
    min-height: 25.5vw;
  }
  .swiper-container {
    overflow: initial !important;
  }
}

@media screen and (max-width: 899px) {
  .swiper-slide {
    min-height: 33vw;
  }
}

@media screen and (max-width: 599px) {
  .swiper-slide {
    min-height: 36.5vw;
  }
}

@media screen and (max-width: 480px) {
  .swiper-slide {
    min-height: 43vw;
  }
}

.swiper-pagination {
  bottom: -3px !important;
}

.swiper-pagination .swiper-pagination-bullet {
  position: relative;
  opacity: 1;
  width: 40px;
  height: 4px;
  border-radius: 7px;
  background-color: #565B79;
}

.swiper-pagination .swiper-pagination-bullet:before {
  content:'';
  position: absolute;
  background-color: #565B79;
  border-radius: 7px;
  top: 0;
  left: 0;
  display: block;
  height: 4px;
  width: 0;
}

.swiper-pagination .swiper-pagination-bullet-active:before {
  background: rgba(255, 255, 255, .9);
  width: 100%;
  animation: progress 6s linear 1;
}