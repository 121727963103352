@import "../../../styles/variables.scss";

.popup {
    width: 100%;
    max-width: 400px !important;
    padding: 55px 32px 32px;
    background: $container-bg-color;
    box-shadow: $popup-shadow-color;
    border-radius: $container-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div:first-of-type {
        top: 16px !important;
        right: 16px !important;
        width: 16px !important;
        height: 16px !important;
    }

    &.isWhite {
        background-color: $primary-text-color;

        .popup__title,
        .popup__description {
            color: $primary-text-dark-color !important;
        }
    }

    @media screen and (max-width: $X-Small-m) {
        justify-content: flex-start;
        max-width: 100% !important;
        height: 100%;
        padding: 63px 16px 40px;

        & > div:first-of-type {
            display: none;
        }

        &.isWhite {
            border-radius: 0;
        }
    }
}

.popup__icon {
    position: relative;
}

.popup__title {
    margin: 21px 0 7px;
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: $font-semibold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $primary-white-color;
    text-transform: uppercase;
}

.popup__description {
    max-width: 340px;
    margin: 0 auto 16px;
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: $primary-white-color !important;

    a {
        color: $primary-white-color !important;
    }

    @media screen and (max-width: $X-Small-m) {
        margin: 0 auto 24px;
    }
}

.popup__confirmation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    button {
        width: 160px;
        height: 42px;
        border-radius: 8px !important;
        font-size: 14px !important;
        line-height: 17px !important;

        @media screen and (max-width: $X-Small-m) {
            width: 100%;
            font-size: 16px !important;
            line-height: 19.5px !important;
        }

        @media screen and (max-width: $X-Small-xs) {
            width: 140px;
        }
    }

    @media screen and (max-width: $X-Small-m) {
        width: 100%;
    }
}

.underlinedBtn {
    font-family: $primary-font-family;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: $primary-text-dark-color;
    color: $primary-text-dark-color !important;
    transition: text-decoration-color .3s;
    cursor: pointer;

    &:hover {
        text-decoration-color: transparent;
    }

    @media screen and (max-width: $X-Small-m) {
        font-size: 16px;
    }
}

.popup__isFrozen__account {
    position: relative;
    padding: 48px 32px 32px;

    @media screen and (max-width: $X-Small) {
        padding: 55px 10px 32px;
    }

    @media screen and (max-width: $X-Small-m) {
        height: 100%;
    }

    &.isWhite {
        .popup__description {
            text-transform: none;
            color: $secondary-dark-color !important;
    
            a {
                text-decoration: underline;
                text-decoration-color: $secondary-dark-color;
                color: $secondary-dark-color !important;
                transition: all .3s;
    
                &:hover {
                    text-decoration-color: transparent;
                }
            }

            @media screen and (max-width: $X-Small) {
                max-width: none;
            }

            @media screen and (max-width: $X-Small-m) {
                margin: 0 auto 16px;
            }

            @media screen and (max-width: $X-Small-s) {
                font-size: 13px;
            }
        }
    
        .popup__title {
            margin: 14px 0 7px;
            text-transform: none;
            color: $secondary-dark-color !important;
        }
    }

    .popup__confirmation {
        gap: 0;
    }
}
