@import "../../../styles/variables.scss";

.popup {
    width: 100%;
    max-width: 466px;
    padding: 40px 40px 32px;
    background: linear-gradient(282.94deg, #2AC59E 1.09%, #009483 100%) top center / 100% 80px no-repeat #fff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: $container-border-radius;

    @media screen and (max-width: $X-Small-m) {
        padding: 40px 20px;
    }
}

.popupBody {
    display: flex;
    min-height: unset;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $X-Small-m) {
        padding: 0;
    }
}

.popup__title {
    padding: 16px 0 8px;
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: $font-bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #2D2A26;

    &::first-letter {
        text-transform: uppercase;
    }
}

.popup__description {
    padding-bottom: 20px;
    font-family: $primary-font-family;
    font-weight: normal;
    font-size: 16px;
    line-height: 19.5px;
    text-align: center;
    color: #1A1818;

    @media screen and (max-width: $Large-lg) {
        font-size: 16px;
        line-height: 28px;
    }
}

.popup__confirmation {
    display: flex;
    padding-top: 8px;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: $X-Small-m) {
        width: 100% !important;
    }

    button {
        width: 192px !important;
        height: 40px !important;
        font-size: 16px !important;
        text-transform: capitalize !important;
        margin: auto;
        background-color: #2AC59E !important;

        @media screen and (max-width: $X-Small-xs) {
            width: 100% !important;
            max-width: 140px !important;
        }
    }
}

.confirmation__popup__container {
    height: 40px;
    width: 40px;
}

.successLabel {
    background: #FFFFFF;
    box-shadow: 0px 4px 19px rgb(92 220 187 / 50%);
    border-radius: 177.5px;
    margin: auto;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    img {
        width: 50px;
    }
}