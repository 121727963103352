@import '../../styles/variables';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    .indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        border: 1px solid #9E9E9E;

        svg {
            color: white;
        }

        &.active {
            border: 1px solid $primary-text-dark-color;
        }

        &.completed {
            background-color: #12845B;
            border-color: #12845B;
        }

        .check_wrapper {
            cursor: pointer;
            margin-top: 1px;
        }

        .dot {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: #9E9E9E;

            &.active {
                background-color: $primary-text-dark-color;
            }
        }
    }

    .line {
        margin-top: 1px;
        width: 64px;
        height: 1px;
        background: #9E9E9E;

        &.active {
            background: $primary-text-dark-color;
        }
    }
}
