@import "../../styles/variables.scss";

.wrapperInput {
    position: relative;
    width: 100%;
    margin-bottom: 13px;

    .errorText {
        color: $text-error;
        font-family: $primary-font-family;
        font-size: 11px;
        line-height: 13px;
        text-align: start;

        &.hint_with_error {
            margin-top: 2px;
        }

        &.newSignUpForm {
            height: 13px;
        }

        &.hint_phoneNumber {
            height: 24px;
            display: none;
        }

        &.hint_phoneNumber.hint_with_error {
            display: block;
        }
    }

    .searchIcon {
        top: 10px;
        position: absolute;
        left: 16px;
        z-index: 10;

        path {
            fill: $primary-red-color;
        }

        @media screen and (max-width: $Medium-md-l) {
            top: 13px;
            font-size: 25px;
        }

        &.searchForGames {
            @media screen and (max-width: $Medium) {
                top: 12px;
            }
        }
    }
}

.wrapperInput.medium {
    width: 288px;
}

.input {
    width: 100%;
    height: 100%;
    max-height: 48px;
    font-family: $primary-font-family;
    font-size: 14px;
    line-height: 17px;
    padding: 14px 16px;
    background: transparent;
    border: 0.6px solid #fff;
    border-radius: $input-border-radius;
    color: $primary-white-color;
    outline: none;
    font-weight: 400;

    @media screen and (max-width: $Medium-md-l) {
        max-height: unset;
        height: 56px;
    }

    @media screen and (max-width: $Medium-md) {
        font-size: 16px !important;
        line-height: 20px !important;
    }

    &.error {
        border-bottom: 1.5px solid $text-error !important;

        &:focus {
            border-bottom: 1.5px solid $text-error !important;
        }
    }

    &.errorNewSignUpForm {
        border: 1.5px solid $text-error !important;

        &:focus {
            border: 1.5px solid $text-error !important;
        }
    }

    &.filled_disabled {
        color: $input-placeholder-color-filled_disabled !important;
        border: 0.6px solid $input-placeholder-color-filled_disabled;
    }

    &.filled {
        padding: 16px 0 4px 16px;
    }

    &.absentValue_disabled {
        &::placeholder {
            font-size: 14px !important;
            color: $input-placeholder-color-filled_disabled_dark !important;
        }
    }

    &.absentValue {
        border: 0.6px solid #fff;
        &::placeholder {
            color: #fff !important;
        }
    }

    &.search {
        background: #22222E;
        border-radius: 4px;
        border: none;
        padding-left: 44px;
        height: 40px;
        line-height: 18px;

        @media screen and (max-width: $Medium-md-l) {
            font-size: 16px;
            line-height: 19.5px;
            max-height: 48px;
        }

        &::placeholder {
            color: #BDBDBD;
        }
    }
    &.searchForGames {
        max-height: 48px;
        padding-left: 23px;
        font-size: 20px !important;
        background: rgba(255, 255, 255, .05) !important;
        border: 1px solid #e10000 !important;
        border-radius: 30px;

        &::placeholder {
            color: #fff;
            font-size: 13px;
            line-height: 16px;
        }

        @media screen and (max-width: $Large-lg) {
            height: 40px;
        }

        &::placeholder {
            transform: translateY(-1px) !important;
        }

        @media screen and (max-width: $Medium-md-l) {
            padding: 0 35px 0 15px;

            &::placeholder {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
    &::placeholder {
        color: $input-placeholder-color;
        font-weight: 600;
        font-size: 14px
    }
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &:disabled {
        color: $input-placeholder-color;
    }

    &::-webkit-input-placeholder {
        font-weight: 400;
        font-size: 14px;
    }

    &:-moz-placeholder {
        font-weight: 400;
        font-size: 14px;
    }

    &::-moz-placeholder {
        font-weight: 400;
        font-size: 14px;
    }

    &:-ms-input-placeholder {
        font-weight: 400;
        font-size: 14px;
    }

    & > label {
        color: #3D3D50 !important;
    }

    & > div > fieldset {
        border: 1px solid #3D3D50 !important;
    }

    & > div:hover > fieldset {
        border: 1px solid #3D3D50 !important;
    }

    &.newSignUpForm {
        background: #F5F6FA;
    }
}

.errorInput {
    & > label {
        color: $text-error !important;
    }

    & > div > fieldset {
        border: 1px solid $text-error !important;
    }

    & > div:hover > fieldset {
        border: 1px solid $text-error !important;
    }
}

.label {
    display: block;
    width: 100%;
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-align: left;
    color: $primary-text-dark-color;
    margin-bottom: 3px;

    span {
        margin-left: 3px;
        font-size: 10px;
        font-weight: $font-regular;
        color: $primary-text-dark-color;
    }
}

.topLabel {
    position: absolute;
    top: 16px;
    left: 10px;
    font-family: $primary-font-family;
    font-weight: $font-regular;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    padding: 0 5px;
    z-index: 1;
    background: $container-bg-color;
    cursor: text;
    // transition: all 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;

    @media screen and (max-width: $Medium-md-l) {
        top: 20px;
    }
}

.topLabel_focus {
    @extend .topLabel;

    top: -6px;
    font-weight: $font-semibold;
    font-size: 10px;
    line-height: 12px;

    &.newSignUpForm {
        background: none;
        color: #A4A4A4;
    }
}

.topLabel_focus.filled_disabled {
       color: $input-placeholder-color-filled_disabled;
   }

.hints__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    padding-top: 3px;
    margin-left: -7px;
}

.hint {
    position: relative;
    padding-left: 15px;
    font-family: Inter, sans-serif;
    font-size: 10px;
    line-height: 11px;
    color: $primary-text-dark-color;
    font-weight: normal;
    text-align: initial;
    white-space: nowrap;

    &:before {
        position: absolute;
        top: 3px;
        left: 8px;
        content: '';
        width: 5px;
        height: 5px;
    }

    &.password {
        text-transform: initial;

        &:before {
            border-radius: 50%;
            background-color: #9ca4bf;
        }
    }

    &.isValid {
        &:before {
            left: 7px;
            width: 7px;
            height: 7px;
            background: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L3 5L1 2.89474' stroke='%2324994C' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
        }
    }

    &.isNotValid {
        &:before {
            left: 7px;
            width: 7px;
            height: 7px;
            background: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_32675_200425)'%3E%3Cpath d='M5 1L1 5' stroke='%239CA4BF' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1L5 5' stroke='%239CA4BF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_32675_200425'%3E%3Crect width='6' height='6' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center;
        }
    }
}
