@import "../../styles/variables.scss";

.wrapperFormBlock {
    position: relative;
    display: none;
    justify-content: flex-end;
    z-index: 1100;
    max-width: 1240px;
    width: 100%;
    height: 0;
    bottom: 10vh;
    border-radius: 8px;
    padding-right: 20px;
}

.wrapperBtnFC {
    width: 100%;
    margin-bottom: 15px;

    &.containImage {
        margin-top: 16px;
        margin-bottom: 0;
    }

    button {
        width: 100%;
        height: 54px;
        font-size: 18px;
        line-height: 22px;
    }

    svg {
        width: 24px !important;
        height: 24px !important;
    }

    span {
        margin-right: 15px;
    }

    @media screen and (max-width: $Large-lg-l) {
        margin-bottom: 23px;
        &.containImage {
            margin-bottom: 0;
        }
    }
}

.wrapperForm {
    width: 280px;
    height: max-content;
    color: #000000;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px 16px;

    &.fly {
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    }

    &.wrapperFormModal {
        width: 100%;
        padding: 0;

        &.flexColumn {
            width: 100%;
        }
    }

     @media screen and (max-width: $Medium-md-l) {
         width: 100%;
     }

    .header {
        margin-bottom: 16px;
        text-align: center;

        p {
            font-weight: bold;
            font-size: 18px;
            line-height: 21.6px;
            text-transform: uppercase;
            color: $primary-text-dark-color;
        }

        @media screen and (max-width: $X-Small-m) {
            width: 100%;
        }
    }

    &.inModal {
        button {
            font-size: 14px;
            line-height: 17px;
            text-transform: capitalize;
            height: 52px;

            @media screen and (max-width: $X-Small-m) {
                font-size: 16px;
                height: 45px;
            }
        }
    }

    button {
        width: 100%;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 4px;
        padding: 0 16px;
        height: 37px;
    }

    .wrpBtn {
        margin-bottom: 8px;
    }

    .divider {
        background: #CBC7C7;
        width: 100%;
        height: 0.6px;

        &.inModal {
            margin: 11px 0 13px;
        }
    }
}

.form {
    width: 100%;
    
    & > div {
        margin-bottom: 12px;
    }

    input {
        max-width: 100%;
        border: 1px solid #9ca4bf;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 18px 8px;
        background: #F5F6FA;
        color: $primary-text-dark-color !important;
        font-weight: 500;

        &::placeholder {
            color: $primary-text-dark-color !important;
            font-weight: 500;
         }

        &::-moz-placeholder {
            color: $primary-text-dark-color !important;
            font-weight: 500;
         }

        &:-ms-input-placeholder {
            color: $primary-text-dark-color !important;
            font-weight: 500;
         }
    }

    &.inModal {
        margin-bottom: 9px;

        input {
            padding: 23px 8px;
            border-radius: 4px;

            @media screen and (max-width: $Medium-md-l) {
                padding: 24px 13px;

                &::placeholder {
                    font-size: 16px !important;
                }
            }

            @media screen and (max-width: $X-Small-m) {
                padding: 22px 7px 21px;
                border-radius: 2px;

                &::placeholder {
                    font-size: 13px !important;
                }
            }
        }

        @media screen and (max-width: $X-Small-m) {
            margin-bottom: 9px;

            & > div {
                margin-bottom: 16px;
            }

            button {
                margin-top: 8px;
            }
        }
    }
}

.loginLabel {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: $primary-text-dark-color;
}

.remember__checkbox {
    width: 16px !important;
    height: 16px !important;

    & + span {
        width: 18px !important;
        height: 18px !important;

        svg {
            width: 11px !important;
            path {
                fill: $primary-text-dark-color !important;
            }
        }
    }

    & + span {
        & + label {
            width: 100%;
            padding-left: 8px;
            font-size: 11px;
            line-height: 14px;
            text-align: left;
            color: $primary-text-dark-color;
        }
    }
}

.remember__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: -5px;
}

.checkedCheckBox {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid $primary-text-dark-color !important;
    color:  $primary-text-dark-color
}
.checkBoxLabel {
    color: $input-color;
    font-family: $primary-font-family;;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-transform: capitalize;
}

.iconPassword {
    display: flex;
    top: auto;
}

.forgotPassword {
    font-family: $primary-font-family;;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #2826A5;
    cursor: pointer;
    text-decoration: underline;
    white-space: nowrap;
}

.noAccount {
    font-family: $primary-font-family;;
    font-style: normal;
    font-weight: $font-semibold;
    font-size: 12px;
    line-height: 14px;
    color: #2D2A26;
    cursor: pointer;

    span {
        text-decoration: underline;
        margin-left: 2px;
        font-weight: $font-bold !important;
    }
}

.conditions {
    padding-top: 17px;
    border-top: 0.5px solid #CBC7C7;
    font-family: $primary-font-family;;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 16px;

    &.inModal {
        padding-top: 0;
        border: none;
        font-size: 10px;
    }
}

.hints {
    margin-top: 10px;
    text-align: center;

    p:first-child {
        margin-bottom: 11px;
    }

    a {
        color: #000000;
        text-decoration: underline;
    }

    span {
        font-weight: 600
    }

    &.inModal {
        margin-top: 0;
    }

    @media screen and (max-width: $X-Small-s) {
            p:first-child {
                margin-bottom: 8px;
            }
    }
}

.loginFormImage {
    display: block;
    width: 100%;
}

.errorInRegForm {
    max-width: 100% !important;
}

.borderDiv {
    text-align:center;
    border-bottom: 0.5px solid #CBC7C7;
    position:relative;
    text-transform: uppercase;
    margin: 15px 0;
    width: 100%;

    &.isSignUpMode {
        margin: 8px 0 24px;
    }
}

.wrapperFormModal {
    .borderDiv {
        margin: 15px 0 24px;
    }
}


.borderSpan {
    width: 100%;
    position: absolute;
    top: -7px;
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: #CBC7C7;
    background-color: transparent;
    text-transform: uppercase;
}

.borderSpanSign {
    padding: 0 5px;
    background: #fff;
    color: #A7A7A7;
}

.errorText {
    color: $text-error;
    font-family: $primary-font-family;
    font-size: 11px;
    line-height: 13px;
    text-align: start;
}