@import "../../styles/variables";


.container {
    width: 100%;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;

    &.isShowPreload {
            background: #111a29 !important;
        }

    @media screen and (min-width: $Medium-md-min) and (max-width: $Large-lg) {
        padding: 0 12px 24px 12px;
        gap: 22px;
    }
}

.container__spinner {
    width: 100px;
    height: 100px;
    font-family: $primary-font-family;
    font-weight: $font-bold;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: $primary-red-color;
    text-shadow: 0 4px 30px rgba(231, 185, 76, 0.3);
    border-radius: 50%;
    display: flex;


    @media screen and (min-width: $X-Small-s) and (max-width: $Medium-md) {
        width: 110px;
        height: 110px;
        font-size: 24px;
        line-height: 29px;
        text-shadow: 0 3.17659px 23.8244px rgba(231, 185, 76, 0.3);
    }
}

.circularProgress {
    position: absolute !important;
    color: $primary-red-color !important;
}

.spinnerGif {
    width: 300px
}
