@import "../../../styles/variables.scss";

.content__outer {
    max-width: 1920px;
    margin: 0 auto;
    padding: 26px 80px;
    background-color: #0e1622;

    @media screen and (max-width: $Extra-large-m) {
        padding: 24px 40px;
    }

    @media screen and (max-width: $Large-lg-l) {
        padding: 24px;
    }
}

.content__wrapper {
    position: relative;
    overflow-y: auto;
    width: 100%;
    height: 158px;
    font-family: Montserrat, sans-serif;
    color: $primary-white-color;

    &::-webkit-scrollbar {
        width: 8px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #2b3547;
        border-radius: 1vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #4b5c7a;
        border-radius: 100vw;
    }

    scrollbar-width: thin;
    scrollbar-color: #4b5c7a;

    h1 {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: $font-black;
        line-height: 1.2;
        text-transform: uppercase;
    }

    h2 {
        font-size: 13px;
        font-weight: $font-semibold;
        line-height: 1.4;
        text-align: left;
    }

    h3 {
        font-size: 22px;
        font-weight: $font-semibold;
        line-height: 28px;
    }

    p {
        margin-bottom: 20px;
        font-size: 11px;
        font-weight: $font-regular;
        line-height: 1.4;
    }
}

.content__title {
    display: inline-block;
    padding-right: 40px;
}

.content__body {
    padding-right: 20px;
}