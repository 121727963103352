@import "../../../styles/variables.scss";

.container {
  max-width: 400px;
  border-radius: 8px;
  box-shadow: $popup-shadow-color;
  background-color: $light-bg-color;

  & > div:first-of-type svg {
    transition: all .3s;
    
    &:hover {
      transform: rotate(-90deg);
    }
  }

  & > button {
    top: 16px !important;
    right: 16px !important;
    max-width: 100px;
  }

  @media screen and (max-width: $X-Small) {
    margin: auto;
    min-height: auto;
  }

  @media screen and (max-width: $X-Small-m) {
    width: 100%;
    max-width: none;
    height: 100vh;
    border-radius: 0;

    & > button {
      right: 32px !important;
    }
  }
}

.title {
  max-width: 360px;
  font-family: $primary-font-family;
  font-weight: $font-semibold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: $primary-text-color;
}

.mainSection {
  width: 100%;
  display: flex;
  min-height: 206px;
  padding: 46px 16px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $container-border-radius;
  background: #fff;

  .icon svg {
    width: 44px;
    height: 44px;
    color: transparent !important;
  }

  @media screen and (max-width: $X-Small-m) {
    justify-content: flex-start;
    height: 100%;
    padding: 49px 16px 32px;

    & > button {
      top: 13px !important;
      left: 14px !important;

      &:before {
        left: 4px !important;
      }
    }
  }
}

.form {
  width: 100%;
  padding-top: 16px;

  label {
    color: $input-placeholder-color !important;
    background: $light-bg-color !important;
  }

  .wrapperInput {
    border-radius: $element-border-radius !important;
    border: 1px solid #73788C !important;
    background: $input-validation-bg-color !important;
  }

  input {
    max-height: 48px;
    height: 48px;
    margin-top: 5px;
    padding: 8px 16px;
    font-size: 13px !important;
    line-height: 24px;
    border-radius: $element-border-radius !important;
    border: 1px solid #73788C !important;
    background: $input-validation-bg-color !important;

    &::placeholder {
      font-size: 13px !important;
      color: #999 !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      caret-color: $input-placeholder-color !important;
      -webkit-text-fill-color: $input-placeholder-color !important;
    }
  }

  .wrapperPhone {
    align-items: start;
  }

  @media screen and (max-width: $X-Small-m) {
    width: 100%;
  }
}

.phoneLabel {
  color: $primary-text-dark-color;
  font-size: 13px;
  font-style: normal;
  font-weight: $font-medium;

  span {
    padding-left: 5px;
    color: $primary-text-dark-color;
    font-size: 10px;
    font-weight: $font-regular;
  }
}

.wrapperPhone {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: $element-border-radius;
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: $font-regular;
  font-size: 13px;

  &.hasError {
    margin-bottom: 25px;
  }

  button {
    border-bottom: none !important;
    border-right: 1px solid #999999;
    padding: 13px 42px 12px 14px;
    max-height: 30px;

    img:first-of-type {
      width: 16px;
      height: 16px;
    }

    span {
      padding: 10px 11px 10px 5px;
      font-size: 13px !important;
    }
  }

  button + div {
    left: -1px !important;
  }

  @media screen and (max-width: $X-Small-m) {
    button {
      padding: 13px 39px 12px 7px;
    }
  }
}

.codeSelect {
  width: 108px;
  height: 48px;
  display: flex;
  margin-top: 5px;
  padding: 8px;
  align-items: center;
  color: #494949;
  font-size: 13px !important;
  border-radius: $element-border-radius;
  border: 1px solid #9CA4BF;
  background: $input-validation-bg-color;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    padding: 7px 11px 7px 5px;
    font-weight: 500;

    &:after {
      right: -11px;
    }
  }

  & > div > div > div {
    position: absolute;
    top: 42px;
    left: -16px;
    height: 240px !important;
    width: 410px !important;

    @media screen and (max-width: $X-Small-m) {
      width: calc(100vw - 32px) !important;
      top: 44px;
    }
  }
}


.codePhone {
  font-size: 13px;
  font-weight: $font-medium;
  line-height: 24px;
  padding-left: 32px;
  color: #494949;
}

.codePhoneLine {
  line-height: 16px;
  padding: 10px;
  color: $primaryBorderLine;
}

.inputCodeSelect {
  width: 100%;

  & > div {
    margin-bottom: 5px;
  }

  input + div {
    position: absolute;
    top: 100%;
  }

  .wrapperInputLine {
    margin-bottom: 0 !important;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 23px;

  button {
    width: 160px;
    height: 42px;
    border-radius: 8px;
    font-size: 14px;
    background: $popup-btn-dark;
    color: $primary-text-color;
    box-shadow: 0px 16px 32px -3px rgba(56, 68, 90, 0.15), 0px 4px 8px -3px rgba(56, 68, 90, 0.15);

    @media screen and (max-width: $X-Small-m) {
      width: 100%;
    }
  }
}

.button_cancel {
  width: 160px;
  font-family: $primary-font-family;
  font-size: 13px;
  font-weight: $font-medium;
  line-height: 16.25px;
  text-decoration: underline;
  color: $primary-text-color;
  background-color: transparent;
  cursor: pointer;
  transition: all .3s;
}

.title,
.description,
.hint {
  color: $secondary-dark-color;
}

.title {
  padding-top: 15px;
  font-weight: $font-bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @media screen and (max-width: $X-Small) {
    text-transform: uppercase;
  }
}

.description {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.hint {
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.resendLink {
  font-family: $primary-font-family;
  font-size: 13px;
  font-weight: $font-semibold;
  line-height: 19.5px;
  color: $primary-text-dark-color;
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
}

.needHelp {
  width: 100%;
  margin-top: 16px;
  padding: 0 10px;
  font-family: $primary-font-family;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: $primary-text-dark-color;

  &:after {
      content: '\003F';
  }
}