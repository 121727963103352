@import "../../styles/variables.scss";

.inputContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
}

.inputWrapper {
    position: relative;
    display: block;
    width: 40px;
    height: 45px;
}

.inputWrapper_active {
    @extend .inputWrapper;

    &:after {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        width: 55%;
        height: 1px;
        background-color: #2D2A26;
    }
}

.input {
    width: 100%;
    height: 100%;
    margin-right: 8px;
    border: 1px solid #A7A7A7;
    border-radius: $element-border-radius;
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2D2A26;

    &:last-child {
        margin-right: 0;
    }

    &:focus {
        border: 1px solid #2D2A26;

    }

    &:focus-visible {
        outline: none;
    }
}

.error {
    border: 1px solid $primary-declined-color !important;
}
