@import "../../../styles/variables.scss";

.logOut {
    width: 100%;
    max-width: 500px;
    padding: 31px 16px 24px;
    position: relative;
    display: block;

    @media screen and (max-width: $Medium-md-l) {
        max-width: 466px;
    }

    @media screen and (max-width: $X-Small) {
        max-width: 382px;
    }

    @media screen and (max-width: $X-Small-m) {
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 175px 24px 40px;
    }
}

.title {
    padding: 20px 0 24px;
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: $font-bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $primary-white-color;
}

.message {
    margin-bottom: 32px;
    font-family: $primary-font-family;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: $primary-white-color;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 120px !important;
        height: 40px !important;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding: 0;

        &:first-child {
            margin-right: 12px;
            background: transparent;
            border: 1px solid #E73843;
            &:hover {
                background: #E73843
            }
        }

        @media screen and (max-width: $Medium-md-l) {
            height: 48px !important;
            width: 140px !important;
            padding: 0 10px;
        }
    }
}
