@import "../../../styles/variables.scss";

.banners {
    display: none;
}

.screens {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    @media screen and (max-width: $Large-lg-l) {
        height: calc(100vh - 64px - env(safe-area-inset-bottom));
        overflow: auto;
    }

    @media screen and (max-width: $X-Small) {
        height: calc(100vh - 108px - env(safe-area-inset-bottom));
    }
}

.screens__oldIOS {
    @media screen and (max-width: $X-Small) {
        height: calc(100vh - 158px - env(safe-area-inset-bottom));

        .screen__content {
            padding: 48px 12px;
        }

        .screen__title {
            font-size: 18px !important;
        }
    }
}

@keyframes progress  {
    0% {
        width: 10%;
    }
    50% {
        width: 50%;
    }
    100% {
        width: 100%;
    }
}

.screen {
    position: relative;
    height: 100vh;
    background-color:#3C0D53;
    scroll-snap-align: start;

    &__isFirefox {
        height: calc(100vh - 64px);
    }

    @media screen and (max-width: $Large-lg-l) {
        position: absolute;
        opacity: 0;
        top: 37px;
        z-index: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100% - 37px);
        background-color: $secondary-bg-color;
        transition: all .5s;

        &__active {
            opacity: 1;
            z-index: 1;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;

        @media screen and (max-width: $Large-lg-l) {
            background-position: 70% center;
        }

        @media screen and (max-width: $Large-lg-l) {
            background-position: top;
        }
    }

    &__progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #565B79;

        &:before {
            content:'';
            position: absolute;
            background-color: rgba(255, 255, 255, .9);
            border-radius: 7px;
            top: 0;
            left: 0;
            display: block;
            height: 4px;
            width: 0;
        }

        &__active {
            &:before {
                background: rgba(255, 255, 255, .9);
                width: 100%;
                animation: progress 8s linear 1;
            }
        }

    }

    &__content {
        position: absolute;
        top: 50%;
        left: 19.5vw;
        transform: translateY(-50%);
        max-width: 595px;
        flex-grow: 1;

        @media screen and (min-width: 1921px) {
            left: 6.5vw;
        }

        @media screen and (max-width: $Extra-large-m) {
            left: 9vw;
        }

        @media screen and (max-width: $Large-lg) {
            left: 5vw;
        }

        @media screen and (max-width: $Medium-md-l) {
            max-width: 500px;
        }

        @media screen and (max-width: $Large-lg-l) {
            position: relative;
            top: 0;
            left: 0;
            transform: translateY(0);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 100%;
            margin-top: auto;
            padding: 36px 12px;
        }
    }

    &__sub_title {
        margin-bottom: 13px;
        font-size: 32px;
        font-weight: $font-bold;
        line-height: 1.4;
        text-transform: uppercase;

        @media screen and (max-width: $Large-lg-l) {
            display: none;
        }
    }

    &__title {
        margin-bottom: 63px;
        font-size: 40px;
        font-weight: $font-bold;
        line-height: 1.4;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: normal;

        @media screen and (max-width: $Large-lg-l) {
            max-width: 630px;
            margin-bottom: 24px;
            font-size: 38px;
            text-align: center;
        }

        @media screen and (max-width: $Medium-md-l) {
            font-size: 28px;
        }

        @media screen and (max-width: $Medium-md) {
            max-width: 500px;
        }

        @media screen and (max-width: $X-Small-m) {
            font-size: 24px;
        }

        @media screen and (max-width: 400px) {
            font-size: 18px;
        }
    }

    &__btn {
        padding: 23px 87px;
        border-radius: 4px;
        font-family: $primary-font-family;
        font-size: 24px;
        font-weight: $font-bold;
        line-height: 1;
        color: $primary-dark-color;
        background-color: #87F031;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        transition: all .3s;

        &:hover {
            background-color: #8fe846;
        }

        @media screen and (max-width: $Large-lg-l) {
            width: 100%;
            min-height: 45px;
            padding: 0 87px;
            font-size: 16px;
        }
    }

    &__scroll {
        position: absolute;
        bottom: 47px;
        left: calc(50% - 32px);
        width: 64px;
        height: 64px;
        border: 2px solid $primary-text-dark-color;
        border-radius: 50%;
        animation: down 1.5s infinite;
        cursor: pointer;
        -webkit-animation: down 1.5s infinite;

        &::before {
            content: '';
            position: absolute;
            top: 16px;
            left: 19px;
            width: 21px;
            height: 21px;
            border-left: 2px solid $primary-text-dark-color;
            border-bottom: 2px solid $primary-text-dark-color;
            transform: rotate(-45deg);
        }
        
        @keyframes down {
            0% {
                transform: translate(0);
            }
            20% {
                transform: translateY(15px);
            }
            40% {
                transform: translate(0);
            }
        }
        
        @-webkit-keyframes down {
            0% {
                transform: translate(0);
            }
            20% {
                transform: translateY(15px);
            }
            40% {
                transform: translate(0);
            }
        }

        @media screen and (max-width: $Large-lg-s) {
            bottom: 8vh;
        }

        @media screen and (max-width: $Large-lg-l) {
            display: none;
        }
    }
}

.screens__tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.screens__tab {
    position: relative;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 37px;
    border: none;
    background-color: #111a29;
    cursor: pointer;
    transition: all .5s;
    -webkit-tap-highlight-color: transparent;

    &__title {
        font-family: $primary-font-family;
        font-size: 13px;
        font-weight: $font-semibold;
        line-height: 1.2;
        color: $primary-text-light-color;
    }

    &__active {
        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 3px;
            background-color: #04F803;
        }
    }

    @media screen and (max-width: $X-Small-m) {
        gap: 8px;
    }
}

.target {
    position: absolute;
    height: 64px;
    top: -64px;
    left: 0;
    object-position: 0;
    visibility: hidden;

    @media screen and (max-width: $Medium-md) {
        display: none;
    }
}


.banner {
    &__wrapper {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
        padding-top: 48px;
        background-color: #111a29;

        @media screen and (max-width: $X-Small-m) {
            padding: 16px 16px 11px;
        }
    }

    position: relative;
    width: 100%;
    max-width: 1330px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 60px 33px;
    border-radius: 20px;
    background: linear-gradient(135deg, #262D3B 4.86%, #2A3243 54.91%, #1F2531 100%);

    @media screen and (max-width: $Extra-large-m) {
        max-width: 1200px;
    }

    @media screen and (max-width: $Large-lg-l) {
        max-width: 90vw;
        height: 300px;
    }

    @media screen and (max-width: $Large-lg) {
        height: 250px;
    }

    @media screen and (max-width: $Medium-md-l) {
        height: 212px;
        padding: 40px 32px;
    }

    @media screen and (max-width: $Medium-md) {
        max-width: 95vw;
        height: 223px;
    }

    @media screen and (max-width: $Medium) {
        height: 180px;
    }

    @media screen and (max-width: $X-Small) {
        height: initial;
        padding: 40px 16px;
        border-radius: 8px;
    }

    @media screen and (max-width: $X-Small-m) {
        padding: 22px 16px;
    }

    @media screen and (max-width: $X-Small-xs) {
        padding: 20px 16px;
    }

    &__img  {
        position: absolute;
        top: 50%;
        right: -3%;
        transform: translateY(-50%);
        max-height: 344px;

        @media screen and (max-width: $Large-lg-l) {
            max-height: 280px;
        }

        @media screen and (max-width: $Large-lg) {
            max-height: 240px;
        }

        @media screen and (max-width: $Medium-md-l) {
            max-height: 225px;
        }

        @media screen and (max-width: $Medium-md) {
            right: -1%;
            max-height: 23vw;
        }

        @media screen and (max-width: $Medium) {
            max-height: 28vw;
        }

        @media screen and (max-width: $X-Small) {
            max-height: 27vw;
        }


        @media screen and (max-width: $X-Small-m) {
            top: 35%;
            right: -4%;
            max-height: 25vw;
        }

        @media screen and (max-width: $X-Small-xs) {
            max-height: 22vw;
        }
    }

    &__title {
        max-width: 465px;
        margin-bottom: 13px;
        font-size: 40px;
        font-weight: $font-black;
        line-height: 1.4;
        text-align: left;
        text-transform: uppercase;
        color: $primary-text-dark-color;
        background: linear-gradient(100deg, #F1CF9F 0%, #F0CD9B 0.01%, #DCA557 100%);
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        background-clip: text;
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;

        @media screen and (max-width: $Large-lg-l) {
            font-size: 36px;
        }

        @media screen and (max-width: $Large-lg) {
            font-size: 32px;
        }

        @media screen and (max-width: $Medium-md-l) {
            max-width: 365px;
            margin-bottom: 12px;
            font-size: 28px;
        }

        @media screen and (max-width: $Medium-md) {
            max-width: 280px;
        }

        @media screen and (max-width: $Medium) {
            font-size: 20px;
        }

        @media screen and (max-width: $X-Small) {
            max-width: 190px;
            font-size: 18px;
        }

        @media screen and (max-width: $X-Small-m) {
            max-width: 150px;
            margin-bottom: 11px;
            font-size: 16px;
        }
    }

    &__btn {
        font-family: $primary-font-family;
        font-size: 20px;
        font-weight: $font-regular;
        line-height: 1.4;
        color: $primary-text-light-color;
        text-decoration: underline;
        text-decoration-color: rgba(255, 255, 255, .7);
        cursor: pointer;
        transition: all .3s;

        &:hover {
            text-decoration-color: transparent;
        }

        @media screen and (max-width: $Medium-md-l) {
            min-height: 40px;
            font-size: 16px;
        }

        @media screen and (max-width: $Medium) {
            font-size: 14px;
        }

        @media screen and (max-width: $X-Small) {
            font-size: 12px;
        }
    }
}

.general__promo__title {
    margin-top: 62px;
    font-size: 36px;
    font-weight: $font-bold;
    line-height: 1.4;
    color: $primary-text-light-color;
    text-transform: uppercase;

    @media screen and (max-width: $Large-lg) {
        margin-top: 40px;
        font-size: 26px;
    }

    @media screen and (max-width: $X-Small-m) {
        margin-top: 29px;
        font-size: 18px;
    }
}