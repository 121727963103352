@mixin scrollVertical {
    &::-webkit-scrollbar {
        width: 6px;
        height: 20px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F5F3F3;
        border-radius: 1vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #DEDEE0;
        border-radius: 100vw;
    }

    scrollbar-width: thin;
    scrollbar-color: #DEDEE0;
}
