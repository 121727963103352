@import "../../../styles/variables.scss";

.session_expired {
    max-width: 400px !important;
    padding: 48px 24px 24px;
    background: $light-bg-color !important;

    @media screen and (max-width: $X-Small-m) {
        width: 100%;
        max-width: 100% !important;
        height: 100%;
        padding: 55px 24px 40px;
        overflow: auto;
    }
}

.session_expired__icon {
    margin-bottom: 14px;
}

.session_expired__title {
    margin-bottom: 8px;
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: $font-semibold;
    font-size: 16px;
    line-height: 24px;
    color: #1a1818;
}

.session_expired__message {
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #1a1818;
    margin-bottom: 16px;
}

.session_expired__form {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        display: inline-block;
        margin-bottom: 3px;
        font-size: 13px;
        font-weight: 500;
        line-height: 15px;
        text-transform: initial;
        color: $primary-text-dark-color;
    }

    .inputDiv {
        width: 100%;
        margin-bottom: 12px;
        
        input {
            width: 100%;
            max-width: none;
            height: 48px;
            max-height: 48px;
            border-radius: 4px;
            border: 1px solid #9CA4BF;
            color: $primary-text-dark-color !important;
            background: #F5F6FA;
    
            &::placeholder {
                font-size: 13px;
                color: #999DAD !important;
            }
    
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                caret-color: $primary-text-dark-color;
                -webkit-text-fill-color: $primary-text-dark-color;
            }
    
            @media screen and (max-width: $Medium-md-l) {
                max-height: 48px;
            }
        }
    }

    @media screen and (max-width: $X-Small-m) {
        width: 100%;
        flex-grow: 1;
    }
}

.session_expired__form_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;

    button {
        width: 159px;
        height: 42px;
        padding: 0;
        border-radius: 8px;
        font-size: 14px !important;
    }
}

.button_cancel {
    font-family: $primary-font-family;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.5px;
    text-decoration: underline;
    text-decoration-color: $primary-text-dark-color;
    color: $primary-text-dark-color;
    background-color: transparent;
    cursor: pointer;
    transition: all .3s;

    &:hover {
        text-decoration-color: transparent;
    }
}

.errorText {
    color: $text-error;
    font-family: $primary-font-family;
    font-size: 11px;
    line-height: 13px;
    text-align: start;
}

.errorInInput {
    max-width: 100% !important;
}