@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";
@import "../../styles/scrollStyles.scss";

.back {
    @include background;
    z-index: 1190;
}

.background {
    overflow: hidden;
    height: calc(100% - 65px) !important;
    top: 0;
    justify-content: inherit;
    align-items: flex-end;

    @media screen and (max-width: $X-Small-m) {
        height: calc(100% - 111px) !important;
    }
}

.backgroundAllDisplay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: #04040Bcc;
    display: flex;
    height: 100vh;
    padding: 30px;
    overflow: auto;

    @media screen and (max-width: $X-Small-m) {
        background: #12161c;
    }
}

.container {
    @include scrollVertical;
    z-index: 1006 !important;
    display: none;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    max-width: 414px;
    height: 100%;
    max-height: unset;
    margin: inherit;
    margin-top: 64px;
    padding: 0;
    align-items: flex-start;
    border-radius: 0;
    background: #121212;
    box-shadow: 0px 4px 10px #04040b;
    grid-gap: 40px;
    gap: 40px;
    &::-webkit-scrollbar {
        width: 0;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media screen and (max-width: 1280px) {
        display: grid;
    }

    @media screen and (max-width: $X-Small) {
        margin-left: 0;
        margin-bottom: 0;
    }

    @media screen and (max-width: $X-Small-m) {
        max-width: unset;
        margin-top: 42px;
        height: 100% !important;
    }
}

.wrapper {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: min-content;
    width: 100%;
    padding: 23px 16px 0;
    background-color: #12161c;

    @media screen and (max-width: $Medium-md-l) {
        padding: 23px 16px 35px;
    }

    @media screen and (max-width: $X-Small) {
        padding: 23px 16px 120px;
    }

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
        background-color: #F5F3F3;
        border-radius: 1vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #DEDEE0;
        border-radius: 100vw;
    }

    scrollbar-width: thin;
    scrollbar-color: #DEDEE0;
}

.title {
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $primary-text-light-color;
}

.title_active {
    @extend .title;

    color: $primary-red-color;
}

.navInfoTitle {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 32px;
    font-size: 16px;
    font-weight: 500;
    color: $primary-text-light-color;

    svg {
        margin-right: 17px;
    }
}

.crossButton {
    height: 24px;
}

.navlinksWrapper {
    padding: 10px;
    border-radius: 4px;
    background-color:#1c1b31;

    &.secondary {
        gap: 0;
        padding: 0;

        .navLinks__link {
            height: 45px;
            min-height: 45px;
            padding: 0 32px;
        }
    }
}

.navLinks {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    font-family: $primary-font-family;
    font-size: 16px;
    line-height: 100%;
    color: $primary-text-light-color;
    text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    img {
        width: 20px;
        height: 20px;
    }

    @media screen and (max-width: $Medium-md-l) {
        img {
            width: 24px;
            height: 24px;
            margin-right: 17px;
        }
    }
}

.navLinksInfo {
    border-radius: 4px;
    background-color:#1c1b31;

    &.gamification {
        margin-bottom: 8px;

        .navInfoTitle {
            img {
                margin-right: 6px;
            }
        }

        .navLinksInfoList {
            padding: 0 32px;
        }

        .gamification__item {
            display: flex;
            align-items: center;
            padding: 11px 0;
        }

        .gamification__img {
            margin-right: 15px;
        }
    }
}

.navLinksContacts {
    @extend .navLinksInfo;
    margin-bottom: 8px;

}

.navLinksInfoList {
    overflow: hidden;
    max-height: 0;
    padding: 0 42px;
    transition: max-height .5s;

    &_open {
        max-height: 400px;
        padding-bottom: 10px;
    }

    .navLinks__title {
        padding: 14px 0;
        font-weight: 500;
    }
}

.navLinks_auth {
    @extend .navLinks;

    padding: 8px 32px 8px 64px;
    background: linear-gradient(180deg, #0D0D0D 0%, #2E2E2E 100%);
}

.navLinks__link {
    display: flex;
    padding: 18px 22px;
    align-items: center;
    justify-content: space-between;
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $primary-text-light-color;
    cursor: pointer;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        a {
            color: $primary-red-color
        }
    }

    @media screen and (max-width: $X-Small-xs) {
        font-size: 14px;
        line-height: 16px;
    }
}

.navLinks__link_MyAccount {
    display: flex;
    padding: 20px 32px;
    align-items: center;
    justify-content: space-between;
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $primary-text-light-color;
    cursor: pointer;
    border: none;

    &:first-child {
        border-bottom: 1px solid #4A4A4A;;
    }

    &:hover {
        a {
            color: $primary-red-color
        }
    }
}

.navLinks__link_myAccountFields {
    display: flex;
    padding: 14px 52px;
    align-items: center;
    justify-content: space-between;
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: $primary-text-light-color;
    cursor: pointer;
    border: none;

    &:first-child {
        border-top: 1px solid #4A4A4A;;
    }

    &:hover {
        a {
            color: $primary-red-color
        }
    }
}

.navLinks__link_auth {
    @extend .navLinks__link;

    padding: 16px 0;
    gap: 6px;
    font-size: 13px;
    line-height: 16px;
    color: $primary-text-light-color;
    border-bottom: none;

    &:hover {
        svg {
            color: $primary-red-color
        }
    }
}

.navLinks__title {
    display: flex;
    align-items: center;
    text-transform: capitalize;

    a, span {
        color: #fff;
    }
    &.active {
        a, span {
                color: $primary-red-color;
                font-weight: 700;
            }
    }

    svg,
    img {
        margin-right: 17px;
    }
}

.navLinks__title__contacts {
    svg {
        margin-right: 8px;
    }
}

.navLinks__subtitle__contacts {
    display: inline-block;
    padding-left: 4px;
    font-size: 10px;
    font-weight: $font-light;
    line-height: 24px;
}

.navLinks__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.isWinclub {
    position: relative;
    width: 100%;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    background-color: #FFE895;
    background-image: linear-gradient(180deg, #FFE895 0%, #D09235 100%);;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.isWinclubNavLinks {
    order: -1;
    height: 44px;
    margin-bottom: 8px;
    padding: 0 32px;
    border-radius: 4px;
    background: linear-gradient(266.87deg,#1c1b31 68.25%,#3f3d39 77.86%,#ebc36d 101.26%,#fab200 110.23%);
}

.isWinclubTitle {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    svg {
        width: 116px;
        height: 32px;
        margin-right: 0;
    }
}

.games {
    display: grid;
    padding: 24px;
    grid-template-columns: repeat(2, 1fr);
    gap: 9px 12px;

    @media (max-width: $X-Small-m) {
        display: flex;
        justify-content: center;
        gap: 11px;
    }
}

.game {
    max-height: 96px;
    max-width: 130px;
    cursor: pointer;

    @media (max-width: $X-Small-m) {
        max-height: 130px;
        max-width: 178px;
    }
}

.image {
    width: 100%;
    height: 100%;
}

.divider {
    width: 100%;
    border-bottom: 1px solid $side-menu-separator;
}

.logOut {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    gap: 18px;
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 14px;
    line-height: 17px;
    color: $primary-white-color;
    cursor: pointer;

    img {
        width: 20px;
        height: 20px;
    }

    @media screen and (max-width: $Medium-md-l) {
        gap: 13px;
        font-size: 16px;
        line-height: 19px;
    }

    @media screen and (max-width: $X-Small-m) {
        gap: 20px;
    }
}

.authSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    width: 100%;
    gap: 12px;

    .button {
        height: 40px;
        text-transform: lowercase;

        span:first-child {
            display: inline-block;
        }

        span::first-letter {
            text-transform: uppercase;
        }

        @media screen and (max-width: $Large-lg) {
            height: 48px;
        }
    }

    @media screen and (max-width: $Medium-md-l) {
        gap: 16px;
        margin-bottom: -3px;

        .button {
            width: 239px;
        }
    }

    @media screen and (max-width: $X-Small-m) {
        .button {
            width: 252px;
            font-size: 16px;
            line-height: 19px;
        }
    }
}

.userInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -17px;

    @media screen and (max-width: $Medium-md-l) {
        margin-bottom: -3px;
    }
}

.userProfile {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-right: 10px;
}

.uploadUserPic__image {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    background-size: cover;
    background-position: top center;
}

.userName {
    margin-top: 4px;
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 14px;
    line-height: 17px;
    color: $primary-white-color;
    word-break: break-word;

    @media screen and (max-width: $Medium-md-l) {
        font-size: 16px;
        line-height: 19px;
    }

    @media screen and (max-width: $X-Small-m) {
        margin-top: -1px;
    }
}

.profileBalance {
    height: 100%;
}

.profileBalance__title {
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 12px;
    line-height: 21px;
    text-align: right;
    color: $primary-white-color;

    @media screen and (max-width: $Medium-md-l) {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 17px;
    }
}

.profileBalance__description {
    font-family: $primary-font-family;
    font-weight: $font-semibold;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: $primary-yellow-color;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 61px;
        white-space: nowrap;

    @media screen and (max-width: $Medium-md-l) {
        margin-bottom: -13px;
        font-size: 14px;
        line-height: 17px;
    }
}

.myAccountFields {
    display: flex;
    flex-direction: column;

    background: linear-gradient(0deg, rgba(45,45,45,1) 0%, rgba(0,0,0,1) 100%);;
}

.lowercased {
    text-transform: lowercase;
}
